<template>
  <el-card
    shadow="always"
    :body-style="{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '30px 20px',
      alignItems: 'center',
      gap: '25px',
    }">
    <!-- Application Info -->
    <div class="stats-card-application-info-container">
      <!-- Logo -->
      <el-image
        v-loading="software == undefined"
        class="company-img"
        :src="`https://logos.saasmetrix.io/${internalName}.png`"
        fit="contain">
        <template #error>
          <v-icon name="md-hideimage-round" scale="1.7" />
        </template>
      </el-image>

      <!-- Name, Edit Button and Description -->
      <div class="text-wrapper" @click="handleSoftwareEditButton">
        <div class="text-row">
          <TextMultiline
            data-test-id="software-name"
            :text="currentObject?.display_name || software?.software_name || ''"
            style="
              font-size: 1.2rem;
              font-weight: 600;
              width: minmax(0, max-content);
            "
            :show-tool-tip="false" />

          <SmTooltip v-if="software?.read_only">
            <template #content> {{ i18n.t('readonlyDesc') }} </template>
            <span class="no-data">({{ i18n.t('readonlyShort') }})</span>
          </SmTooltip>
        </div>
        <div style="text-align: left">
          <TextMultiline
            :text="currentObject?.desc_short || ''"
            :lines-to-show="2"
            :show-tool-tip="false" />
        </div>
      </div>
    </div>

    <!-- Stats -->
    <StatsElement
      :display-number="userCount"
      display-i18n-key="views.applicationDetails.statsCard.licences.user"
      icon="md-person-round"
      color="yellow" />
    <StatsElement
      :display-number="optimizationCount"
      display-i18n-key="views.applicationDetails.statsCard.optimizations.title"
      icon="md-rocketlaunch-round"
      color="purple" />
    <StatsElement
      :display-number="monthlyCost"
      is-price
      display-i18n-key="views.applicationDetails.statsCard.spends.title"
      icon="md-payments-round"
      color="primary" />
  </el-card>
</template>

<script lang="ts" setup>
  import {
    OptimizationStatus,
    OptimizationsService,
    SoftwareCostService,
    SoftwareOut,
    UserLicencesService,
  } from '@/client'
  import StatsElement from '@/components/StatsElement.vue'
  import { useI18n } from 'vue-i18n'
  import { useRoute } from 'vue-router'

  const i18n = useI18n()

  const props = defineProps({
    software: {
      type: Object as PropType<SoftwareOut>,
      default: null,
    },
  })

  const emit = defineEmits<{
    openApplicationDetailsDrawer: [value: string]
  }>()

  const route = useRoute()

  const subAccountID = computed(() => route.params.subAccountId as string)
  const isSSO = computed(() => route.query.sso === 'true')

  const internalName = computed(() => {
    if (isSSO.value) {
      return props.software.sub_accounts[subAccountID.value].name
    } else if (props.software?.software_name) {
      return props.software?.software_name
    }
  })

  const currentObject = computed(() => {
    if (isSSO.value) {
      return props.software.sub_accounts[subAccountID.value]
    } else {
      return props.software
    }
  })

  const handleSoftwareEditButton = () => {
    emit('openApplicationDetailsDrawer', 'EditSoftware')
  }

  const optimizationCount = ref()
  const monthlyCost = ref()
  const userCount = ref<number>()
  function fetchStatistics(software_id: string) {
    OptimizationsService.countOptimizationsApiV1ManagementOptimizationsCountGet(
      {
        status: OptimizationStatus.OPEN,
        softwareId: software_id,
      }
    ).then((res) => {
      optimizationCount.value = res
    })

    const query: Record<string, string> = {
      id: software_id,
    }

    if (isSSO.value) {
      query['subAccountId'] = subAccountID.value
    }

    SoftwareCostService.getCostsApiV1SoftwareCostGet(query).then((res) => {
      if (isSSO.value) {
        monthlyCost.value =
          res.software_costs[software_id].sub_account_costs[subAccountID.value]
      } else {
        monthlyCost.value = res.total || 0
      }
    })

    const queryLicences: Record<string, string> = {
      softwareId: software_id,
    }

    if (isSSO.value) {
      queryLicences['subAccountId'] = subAccountID.value
    }

    UserLicencesService.countUniqueUserLicencesApiV1SoftwareLicencesCountUniqueUserLicencesGet(
      queryLicences
    ).then((res) => {
      userCount.value = res
    })
  }

  watch(
    () => props.software,
    () => {
      if (props.software === null) return
      fetchStatistics(props.software._id)
    },
    { immediate: true }
  )

  defineExpose({
    fetchStatistics,
  })
</script>

<style lang="scss" scoped>
  .stats-card-application-info-container {
    display: grid;
    place-content: center;
    grid-template-columns: 50px 1fr;
    gap: 10px;
    flex: 1;
    max-width: 40%;
    .company-img {
      height: 100%;
      width: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    .text-wrapper {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin-left: 10px;
      cursor: pointer;

      .text-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
      }
    }
  }
  .edit-button-content {
    display: flex;
    align-items: center;
    gap: 0.4rem;
  }

  .edit-button {
    .edit-icon {
      fill: var(--sm-petrol);
    }

    &:hover {
      .edit-icon {
        fill: var(--sm-white);
      }
    }
  }
</style>
