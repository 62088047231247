import { defineStore } from 'pinia'

import { AccountIn, Account, UsersService } from '@/client'
import { useFetch } from '@/common/useFetch'

export const useUserStore = defineStore('userStore', () => {
  const user = ref<Account[] | null>(null)
  const lastReload = ref<Date | null>(null)

  const loading = ref(false) // true, if unfiltered users are currently loading

  function setUser(newUser: Account[] | null) {
    user.value = newUser
  }

  async function reloadUser() {
    const { data, error, loading, fetchData } = useFetch(
      () =>
        UsersService.getUsersApiV1ManagementUsersGet({
          emailOrAlias: undefined,
        }),
      []
    )
    return fetchData().then(() => {
      setUser(data.value)
      lastReload.value = new Date()
      return { data: data.value, error: error.value, loading: loading.value }
    })
  }

  //   Getter
  // Following getter are supported:
  // - getUser: Gets all users that match the filter

  // Gets a single user
  async function getUser(
    { allowCached }: { allowCached?: boolean } = { allowCached: true }
  ) {
    // If user are currently loading, wait for it
    while (loading.value) {
      await new Promise((resolve) => setTimeout(resolve, 100))
    }

    loading.value = true
    let data: Account[] | null = user.value
    if (!isUserLoaded.value || !allowCached || !lastReload.value) {
      // Reload user
      ;({ data } = await reloadUser())
    }
    loading.value = false
    return data
  }

  function fetchUser() {
    const { data, error, loading, fetchData } = useFetch(
      () =>
        UsersService.getUsersApiV1ManagementUsersGet({
          emailOrAlias: undefined,
        }),
      []
    )
    const promise = fetchData()
    return { data, error, loading, promise }
  }

  async function getUserByID(id: string): Promise<Account | undefined> {
    await getUser({ allowCached: true }) // Make sure users are loaded
    return user.value?.find((u) => u._id === id)
  }

  async function getUserByEmail(email: string) {
    await getUser({ allowCached: false }) // Make sure users are loaded
    return user.value?.find((u) => u.email === email)
  }

  async function createUser(user: AccountIn) {
    return UsersService.addUserApiV1ManagementUsersPost({ requestBody: user })
  }

  //   Helper
  const isUserLoaded = computed(() => {
    return user.value !== null
  })

  return {
    user,
    setUser,
    getUser,
    fetchUser,
    getUserByID,
    getUserByEmail,
    createUser,
  }
})
