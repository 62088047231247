<template>
  <SettingsConfirmCancelSubscriptionDialog
    v-model:visibility="subscriptionDialogVisible" />

  <!-- settings-container defined in parent -->
  <div class="settings-container">
    <h3>{{ i18n.t('sidebar.settings.subscription') }}</h3>

    <!-- <div class="upcoming-invoice">{{ store.companyInfo }}</div> -->
    <div style="display: grid; grid-template-columns: 200px 1fr">
      <span>{{ i18n.t('name') }}:</span>
      <span>{{ subscriptionInfo?.plan_info.name }}</span>
      <span>Abgerechnete Nutzer:</span>
      <span>{{ subscriptionInfo?.plan_info.used_seats }}</span>
    </div>

    <div class="old-invoices">
      <h4>{{ i18n.t('invoices') }}</h4>
      <span>
        {{ i18n.t('views.settings.subscription.invoiceHelp') }}
        <a href="mailto:support@saasmetrix.io">support@saasmetrix.io</a>.
      </span>
      <!-- Invoice Table -->
      <SmTable
        key-field="id"
        :columns="columns"
        :data="invoices"
        row-key="invoiceNumber"
        :pagination="false"
        :loading="false"
        style="text-align: right; margin-top: 20px"
        :scroll="{ x: 800 }">
        <!-- Actions -->
        <template #actions="{ row }">
          <a
            v-if="row.hosted_invoice_url"
            :href="row.hosted_invoice_url"
            target="_blank"
            rel="noopener noreferrer">
            <el-button type="text" size="mini">
              {{ i18n.t('view') }}
            </el-button>
          </a>
        </template>

        <!-- Date -->
        <template #created="{ row }">
          <span v-if="row.created">
            {{ new Date(row.created * 1000).toLocaleDateString() }}
          </span>
        </template>

        <!-- Amount -->
        <template #amount_due="{ row }">
          <span v-if="row.amount_due && row.currency">
            {{ (row.amount_due / 100).toFixed(2) }}
            {{ row.currency.toUpperCase() }}
          </span>
        </template>
      </SmTable>
    </div>

    <div class="subscription">
      <h4 class="mb-4">
        {{ i18n.t('views.settings.account.cancelSection.header') }}
      </h4>

      <SmButton type="danger" @click="subscriptionDialogVisible = true">
        {{ i18n.t('views.settings.account.cancelSection.cancelButton') }}
      </SmButton>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { BillingService, Invoice, SubscriptionInfoOut } from '@/client'
  import SmTable from '@/components/sm/SmTable.vue'
  import { Column } from './sm/SmTable.types'

  const i18n = useI18n()

  const subscriptionDialogVisible = ref(false)

  const invoices: Ref<Invoice[]> = ref([])
  const subscriptionInfo = ref<SubscriptionInfoOut>()

  BillingService.getSubscriptionApiV1BillingSubscriptionInfoGet({})
    .then((res) => {
      subscriptionInfo.value = res
    })
    .catch((err) => {
      console.error(err)
    })

  const columns: Column<Invoice>[] = [
    {
      label: 'ID',
      key: 'number',
      sortable: false,
      width: '200px',
    },
    {
      label: 'Date',
      key: 'created',
      sortable: false,
      width: 1,
    },
    {
      label: 'Amount',
      key: 'amount_due',
      sortable: false,
      width: 1,
    },
    {
      label: 'Status',
      key: 'status',
      sortable: false,
      width: 1,
    },
    {
      label: '',
      key: 'actions',
      width: '40px',
      sortable: false,
    },
  ]

  BillingService.getInvoicesApiV1BillingInvoicesGet({})
    .then((res) => {
      invoices.value = res
    })
    .catch((err) => {
      console.error(err)
    })
</script>
