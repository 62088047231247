<template>
  <el-image
    v-if="props.softwareName"
    class="icon app-icon"
    :src="`https://logos.saasmetrix.io/${props.softwareName}.png`">
    <template #error>
      <v-icon name="md-hideimage-round" class="icon" />
    </template>
  </el-image>
  <v-icon
    v-else
    class="icon"
    name="md-hideimage-round"
    fill="rgba(0, 0, 0, 0.1)" />
</template>

<script setup lang="ts">
  // because props are used in the template
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const props = withDefaults(
    defineProps<{ softwareName: string | undefined; size?: string }>(),
    { size: '20px' }
  )
</script>

<style scoped lang="scss">
  .icon {
    height: v-bind(size);
    width: v-bind(size);
    transition: all 0.3s ease-in-out;
  }
</style>

<style lang="scss">
  .app-icon path {
    opacity: 0.7;
  }
</style>
