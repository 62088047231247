<template>
  <SmDialog
    v-model:visibility="visibility"
    :title="i18n.t('languageSelect')"
    size="small">
    <div class="languages">
      <div
        :class="['language', { selected: currentLanguage === 'de' }]"
        @click="setLanguage('de')">
        <div class="flag-wrapper" @click="visibility = true">
          <img
            class="flag object-cover"
            src="https://logos.saasmetrix.io/flags/de.svg"
            style="height: 100%"
            :alt="i18n.t('german')" />
        </div>
        <span>{{ i18n.t('german') }}</span>
      </div>
      <div
        :class="['language', { selected: currentLanguage === 'en' }]"
        @click="setLanguage('en')">
        <div class="flag-wrapper" @click="visibility = true">
          <img
            class="flag object-cover"
            src="https://logos.saasmetrix.io/flags/en.svg"
            style="height: 100%"
            :alt="i18n.t('english')" />
        </div>
        <span>{{ i18n.t('english') }}</span>
      </div>
    </div>
  </SmDialog>
</template>

<script setup lang="ts">
  import { useStorage } from '@vueuse/core'

  import { useI18n } from 'vue-i18n'

  import SmDialog from '@/components/sm/SmDialog.vue'

  const i18n = useI18n()

  const emit = defineEmits(['update:visibility'])

  const props = defineProps({
    visibility: {
      type: Boolean,
      required: true,
    },
  })

  const visibility = computed({
    get: () => props.visibility,
    set: (value) => emit('update:visibility', value),
  })

  const currentLanguage = useStorage('language', 'en')

  function setLanguage(language: string) {
    currentLanguage.value = language
    i18n.locale.value = language
    visibility.value = false
  }
</script>

<style scoped lang="scss">
  .flag-wrapper {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .language.selected .flag-wrapper {
    border: 4px solid var(--sm-primary);
  }

  .language.selected {
    border: 1px solid var(--sm-primary);
    background-color: var(--el-color-primary-light-9);
  }

  .language {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    box-sizing: border-box;
    height: 100%;
    padding: 0.5rem;
    font-size: 1.1rem;
    border-radius: var(--border-radius-base);
    border: 1px solid transparent;
    aspect-ratio: 1;
    cursor: pointer;

    &:hover {
      border: 1px solid var(--sm-primary);
    }
  }

  .languages {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    align-items: center;
  }
</style>
