<template>
  <div
    ref="tableWrapperRef"
    class="table-wrapper"
    style="
      display: flex;
      flex-direction: column;
      height: 100%;
      position: relative;
    ">
    <SmLoading :loading="props.loading" />

    <!-- Table Header -->
    <div
      v-if="!hideHeader"
      class="flex shrink-0 grow-0 basis-auto gap-6 border-b border-gray-200 pb-3 dark:border-gray-700"
      :style="{ paddingRight: headerPadding + 'px' }">
      <!-- Select all -->
      <div
        v-if="props.selectable"
        style="width: 40px; display: flex; justify-content: center">
        <input
          v-model="selectAllVal"
          type="checkbox"
          :disabled="props.selectDisabled"
          @change="handleSelectAllChange" />
      </div>
      <!-- Drag Handle -->
      <div
        v-if="props.draggable"
        style="width: 26px; display: flex; justify-content: center"></div>
      <div
        v-for="(column, index) in props.columns"
        :key="column.key"
        class="table-header-cell"
        :style="{
          paddingLeft: index === 0 && !selectable ? '10px' : '0',
          ...cellStyle(column),
        }"
        @click="handleHeaderClick(column.key)">
        <!-- Header Label -->
        <slot :name="`header-${column.key}`" :column="column">
          <TextMultiline :text="column.label" :show-tool-tip="false" />
        </slot>
        <!-- Sorting -->
        <div v-if="column.sortable !== false" :class="{ sort: true }">
          <v-icon
            name="io-caret-down-outline"
            scale="0.85"
            :class="{
              'sort-icon': true,
              asc: sorting.asc && sorting.by === column.key,
              active: sorting.by === column.key,
            }" />
        </div>
      </div>
    </div>

    <!-- Table Content -->
    <div
      class="table-content shrink grow basis-auto"
      :style="{
        maxHeight: `calc(100% - ${hasFooter && !hideHeader ? 64 : hasFooter || !hideHeader ? 32 : 0}px)`,
      }">
      <template v-if="!props.draggable">
        <DynamicScroller
          ref="scrollerRef"
          :style="{ overflow: notScrollable ? 'hidden' : 'auto' }"
          :items="data"
          :min-item-size="58"
          class="scroller h-auto"
          :key-field="props.keyField"
          @scroll.passive="handleScroll">
          <template
            #default="{
              item,
              index,
              active,
            }: {
              item: T
              index: number
              active: boolean
            }">
            <DynamicScrollerItem
              :item="item"
              :active="active"
              :watch-data="false"
              :data-index="index">
              <component
                :is="props.rowLink ? 'a' : 'div'"
                class="table-row border-b border-gray-200 dark:border-gray-700"
                :class="[
                  {
                    'border-b-0': index === data.length - 1,
                    'cursor-pointer': hasCellClickFn,
                  },
                ]"
                :style="rowStyle"
                :href="props.rowLink ? props.rowLink(item) : undefined"
                @click="handleLinkClick">
                <SmTableRow
                  :data="item"
                  :key-field="props.keyField"
                  :selectable="selectable"
                  :select-disabled="selectDisabled"
                  :is-checked="checkboxSelection.includes(item[props.keyField])"
                  :columns="columns"
                  @handle-selection-change="handleSelectionChange"
                  @handle-cell-click="handleCellClick">
                  <template
                    v-for="column in columns"
                    :key="column.key"
                    #[column.key]>
                    <slot :name="column.key" :row="item"></slot>
                  </template>
                </SmTableRow>
              </component>
            </DynamicScrollerItem>
          </template>
        </DynamicScroller>
      </template>

      <template v-else>
        <VueDraggable
          ref="scrollerRef"
          v-model="data"
          :animation="150"
          handle=".handle">
          <div
            v-for="(item, index) in data"
            :key="item._id"
            class="table-row border-b border-gray-200 dark:border-gray-700"
            :class="[{ 'border-b-0': index === data.length - 1 }]"
            :style="rowStyle">
            <SmTableRow
              :data="item"
              :selectable="selectable"
              :columns="columns"
              :key-field="props.keyField"
              draggable
              @handle-selection-change="handleSelectionChange"
              @handle-cell-click="handleCellClick">
              <template
                v-for="column in columns"
                :key="column.key"
                #[column.key]>
                <slot :name="column.key" :row="item" :index="index"></slot>
              </template>
            </SmTableRow>
          </div>
        </VueDraggable>
      </template>

      <!-- No Data -->
      <div
        v-if="!props.loading && modelData.length === 0"
        class="no-data"
        style="
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
        ">
        <slot name="no-data">
          <span style="padding: 10px 20px">{{ i18n.t('noData') }}</span>
        </slot>
      </div>
    </div>

    <!-- Table Footer -->
    <div
      v-if="hasFooter"
      class="flex shrink-0 grow-0 basis-auto gap-6 border-t border-gray-200 pt-3 dark:border-gray-700"
      :style="{ paddingRight: headerPadding + 'px' }">
      <slot name="footer">
        <div
          v-for="(column, index) in props.columns"
          :key="column.key"
          :style="
            index === 0
              ? {
                  paddingLeft: selectable ? '40px' : '10px',
                  ...cellStyle(column),
                }
              : cellStyle(column)
          ">
          <!-- Footer Label -->
          <slot :name="`footer-${column.key}`" :column="column">
            <p class="text-contrast-muted" @click="console.log(column.footer)">
              {{ column.footer?.value ?? column.footer }}
            </p>
          </slot>
        </div>
      </slot>
    </div>
  </div>
</template>

<script setup generic="T extends TableData" lang="ts">
  export interface TableData {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
    sorting?: number
  }

  import { useElementSize } from '@vueuse/core'
  import { useI18n } from 'vue-i18n'

  // @ts-expect-error: No types for vue-virtual-scroller
  import { DynamicScroller } from 'vue-virtual-scroller'
  import SmLoading from './SmLoading.vue'
  import { Column, Sorting } from './SmTable.types'
  import TextMultiline from './TextMultiline.vue'
  import SmTableRow from './SmTableRow.vue'
  import { VueDraggable } from 'vue-draggable-plus'
  import { TagsService, SortableOrderUpdate } from '@/client'
  import { getCurrentInstance } from 'vue'

  const i18n = useI18n()

  const scrollerRef = ref<HTMLElement>()
  const tableWrapperRef = ref<HTMLDivElement>()
  const slots = useSlots()

  const hasCellClickFn = computed(() => {
    const instance = getCurrentInstance()

    return !!instance?.vnode?.props?.onCellClick
  })

  interface Props {
    rowLink?: (row: T) => string
    keyField: keyof T
    columns: Column<T>[]
    itemHeight?: string
    loading?: boolean
    selectable?: boolean
    selectDisabled?: boolean
    notScrollable?: boolean
    defaultSorting?: Sorting
    hideHeader?: boolean
    externalSorting?: boolean
    rowStyle?: Record<string, string>
    draggable?: boolean
  }

  // Define Props
  const props = withDefaults(defineProps<Props>(), {
    rowLink: undefined,
    itemHeight: '50px',
    loading: false,
    selectable: false,
    selectDisabled: false,
    columns: () => [],
    notScrollable: false,
    defaultSorting: () => {
      return {
        by: 'email',
        asc: false,
      }
    },
    hideHeader: false,
    externalSorting: false,
    rowStyle: () => {
      return {}
    },
    draggable: false,
  })

  const key: keyof T = props.keyField as keyof T

  const emit = defineEmits<{
    // Click events
    'cell-click': [iRow: number, columnKey: string, row: T]
    'row-click': [iRow: number, row: T]
    'column-click': [columnKey: string]

    // Drag Events
    'row-drag': []

    // Selection Events
    'select-change': [selection: T[]]
    selectedAll: [selection: T[]]
    unselectedAll: [selection: T[]]
    selected: [row: T]
    unselected: [row: T]

    // Srcoll Events
    'scroll-end-reached': [currentLength: number]
  }>()

  //   Click Handlers
  function handleCellClick(data: { iRow: number; row: T; columnKey: string }) {
    emit('cell-click', data.iRow, data.columnKey, data.row)
    emit('row-click', data.iRow, data.row)
    emit('column-click', data.columnKey)
  }

  const sorting = ref(props.defaultSorting)

  const modelData = defineModel<T[]>('data', { required: true })

  const isExternalSorting = computed(() => {
    return props.draggable ? true : props.externalSorting
  })

  const hasFooter = computed(() => {
    return (
      Object.keys(slots).some((slotName) => slotName.startsWith('footer-')) ||
      props.columns.some((column) => 'footer' in column)
    )
  })

  const data = computed({
    get() {
      if (modelData.value.length > 0 && !isExternalSorting.value) {
        return sortByColumn(sorting.value.by, sorting.value.asc)
      } else {
        return modelData.value
      }
    },
    set(newValue) {
      modelData.value = newValue
    },
  })

  if (props.draggable) {
    watch(data, (newData) => {
      const idArray: SortableOrderUpdate = {
        _ids: newData.map((item) => item._id),
      }

      TagsService.reorderTagsApiV1ManagementTagsReorderPost({
        requestBody: idArray,
      }).then(() => {
        newData.forEach((item, index: number) => {
          item.sorting = newData.length - index
        })
        emit('row-drag')
      })
    })
  }

  //   Cell Style
  const columnStyleCache: Record<string, Record<string, number | string>> = {}
  function cellStyle(column: Column<T>) {
    // Check cache
    if (columnStyleCache[column.key]) {
      return columnStyleCache[column.key]
    }

    const style: Record<string, number | string> = {}

    // Get width
    if (typeof column.width === 'number') {
      style['flex'] = `${column.width}`
    } else {
      style['width'] = column.width
    }

    // Cache style
    if (!columnStyleCache[column.key]) {
      columnStyleCache[column.key] = {}
    }
    columnStyleCache[column.key] = style

    // Return style
    return style
  }

  // ###########################################################
  // ##################### Sorting #############################
  // ###########################################################
  function sortByColumn(key: keyof T, asc: boolean) {
    const columnDefinition = props.columns?.find((c) => c.key === key)
    let sortFn = columnDefinition?.sortFn

    // Default sort function
    if (!sortFn) {
      sortFn = (a: T, b: T) => {
        if (!a[key] && !b[key]) {
          return 0
        }
        if (!a[key]) {
          return -1
        }
        if (!b[key]) {
          return 1
        }
        if (a[key] < b[key]) {
          return -1
        }
        if (a[key] > b[key]) {
          return 1
        }
        return 0
      }
    }

    const sorted = [...modelData.value].sort(sortFn)

    return asc ? sorted : sorted.reverse()
  }

  function handleHeaderClick(key: string) {
    if (props.columns?.find((c) => c.key === key)?.sortable === false) {
      return // Column is not sortable
    }
    changeSorting(key)
  }

  function changeSorting(key: string) {
    if (sorting.value.by === key) {
      sorting.value.asc = !sorting.value.asc
    } else {
      sorting.value.by = key
      sorting.value.asc = true
    }
  }

  // ###########################################################
  // ##################### Selection ###########################
  // ###########################################################
  const selection: Ref<Array<T>> = ref([])
  const checkboxSelection: Ref<Array<T[keyof T]>> = ref([])

  function resetSelection() {
    checkboxSelection.value = []
  }

  const selectAllVal = computed(() => {
    const selectedKeys = new Set(checkboxSelection.value)
    return modelData.value.every((item) => selectedKeys.has(item[key]))
  })

  function handleSelectAllChange() {
    const newSelection = new Set(selection.value)

    if (selectAllVal.value) {
      emit('unselectedAll', modelData.value)
      modelData.value.forEach((item: T) => {
        newSelection.delete(item)
      })
    } else {
      emit('selectedAll', modelData.value)
      modelData.value.forEach((item: T) => {
        newSelection.add(item)
      })
    }

    selection.value = Array.from(newSelection) as T[]

    checkboxSelection.value = [...selection.value.map((i) => (i as T)[key])]

    emit('select-change', selection.value)
  }

  /**
   * Selects or deselects an item
   * @param item The item to select/deselect
   * @param value If true, the item will be selected. If false, the item will be deselected. If undefined, the item will be toggled.
   */
  function select(item: T, value?: boolean) {
    if (value === undefined) {
      if (checkboxSelection.value.includes(item[key])) {
        checkboxSelection.value = checkboxSelection.value.filter(
          (i) => i !== item[key]
        )
      } else {
        checkboxSelection.value.push(item[key])
      }
    } else if (value) {
      checkboxSelection.value.push(item[key])
    } else {
      checkboxSelection.value = checkboxSelection.value.filter(
        (i) => i !== item[key]
      )
    }
    emit('select-change', selection.value)
  }

  function selectById(id: string) {
    const item = modelData.value.find((i) => i[key] === id)
    if (item) {
      select(item)
    }
  }

  function selectByIds(ids: string[]) {
    ids.forEach((id) => selectById(id))

    return selection.value
  }

  function deselect(item: T) {
    selection.value = selection.value.filter((i) => i !== item)
    emit('select-change', selection.value)
  }

  function deselectById(id: string) {
    checkboxSelection.value = checkboxSelection.value.filter((i) => i !== id)
  }

  function clearSelection() {
    checkboxSelection.value = []
  }

  const { width: scrollerWidth } = useElementSize(scrollerRef)
  const { width: tableWrapperWidth } = useElementSize(tableWrapperRef)

  const headerPadding = computed(() => {
    return tableWrapperWidth.value - scrollerWidth.value
  })

  function handleSelectionChange(data: { event: Event; item: T }) {
    const target = data.event.target as HTMLInputElement
    const value = target.checked

    if (value) {
      select(data.item, value)
      emit('selected', data.item)
    } else {
      deselect(data.item)
      emit('unselected', data.item)
    }

    emit('select-change', selection.value)
  }

  const handleLinkClick = (e: Event) => {
    if (props.rowLink) {
      e.preventDefault()
    }
  }

  watch(
    () => checkboxSelection.value,
    () => {
      const selectedKeys = new Set(checkboxSelection.value)
      const newSelection = new Set()

      // keep previous selection
      selection.value.forEach((item) => {
        if (selectedKeys.has(item[key])) {
          newSelection.add(item)
        }
      })

      // add new selection
      modelData.value.forEach((item) => {
        if (selectedKeys.has(item[key])) {
          newSelection.add(item)
        }
      })

      selection.value = Array.from(newSelection) as T[]
    },
    { deep: true }
  )
  // ###########################################################
  // ##################### Srolling ############################
  // ###########################################################

  const LOAD_THRESHOLD_PX = 800
  let lastScrollEmitted = 0

  function handleScroll(e: Event) {
    const target = e.target as HTMLElement

    const currentScroll = target.scrollTop
    const scrollableDistance = Math.max(
      0,
      target.scrollHeight - target.offsetHeight
    )

    if (
      currentScroll >= scrollableDistance - LOAD_THRESHOLD_PX &&
      modelData.value.length !== lastScrollEmitted
    ) {
      // Your infinite loading logic here
      lastScrollEmitted = modelData.value.length
      emit('scroll-end-reached', modelData.value.length)
    }
  }

  // Reset last scroll emitted when data changes
  watch(
    () => modelData.value,
    () => {
      lastScrollEmitted = 0
    }
  )

  onMounted(() => {
    //   Set initial sorting
    changeSorting(props.defaultSorting.by)
  })

  //   Define Exposes
  defineExpose({
    selection,
    handleSelectAllChange,
    changeSorting,
    resetSelection,
    select,
    selectById,
    selectByIds,
    deselect,
    deselectById,
    clearSelection,
    sorting,
  })
</script>

<style scoped lang="scss">
  $gap: 1.5rem;
  $select-width: 40px;

  .table-content {
    display: flex;
    flex-direction: column;
    font-size: 0.95rem;
  }

  .table-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0 $gap;
    min-height: 52px;

    &:hover {
      background-color: var(--el-fill-color-light);
    }

    // Remove link underline
    text-decoration: none;
    color: inherit;
  }

  .table-select {
    width: $select-width;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .table-cell {
    display: flex;
    justify-content: left;
    color: var(--el-table-text-color);
  }

  .table-header {
    display: flex;
    font-size: 1rem;
    padding-bottom: 8px;
    border-bottom: 2px solid var(--el-border-color-lighter);
    gap: 0 $gap;

    &-cell {
      display: flex;
      justify-content: left;
      align-items: center;
      font-weight: 600;
    }
  }

  .table {
    color: var(--el-text-color-regular) !important;
    font-weight: 400;
  }

  //   Sorting
  .sort {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 5px;

    &-icon {
      color: var(--el-text-color-disabled);
      transition:
        rotate 0.2s ease-in-out,
        color 0.2s ease-in-out;

      &.asc {
        rotate: 180deg;
      }

      &.active {
        color: var(--el-color-primary);
      }

      &:hover {
        color: var(--el-color-primary);
      }
    }

    .scroller {
      overflow-y: auto;
      position: relative;
      height: 100%;
    }
  }

  input {
    accent-color: var(--el-color-primary);
    cursor: pointer;
  }

  .sm-table {
    position: relative;
  }
</style>

<style>
  .vue-recycle-scroller__item-view {
    pointer-events: visiblePainted;
  }
</style>
