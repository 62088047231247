<template>
  <div class="kanban">
    <h2>{{ i18n.t('tasks') }}</h2>

    <div class="kanban-header">
      <div class="kanban-mode-switch">Links</div>
      <div class="kanban-mode-switch">{{ i18n.t('all') }}</div>
    </div>

    <draggable
      v-model="columns"
      group="columns"
      :animation="150"
      handle=".drag-handle"
      item-key="id"
      class="column-wrapper">
      <template #item="{ element: column }: { element: Column }">
        <div class="column">
          <header class="drag-handle column-header">
            <div class="column-status-indicator"></div>
            <span class="column-title">
              {{ column.title }} ({{ column.tasks.length }})
            </span>
            <!-- Menu -->
            <div class="column-menu">
              <v-icon name="md-morevert-round" />
            </div>
          </header>

          <div class="task task-add">
            <v-icon name="md-add-round" />{{ i18n.t('addNewTask') }}
          </div>

          <draggable
            v-model="column.tasks"
            :group="{
              name: 'tasks',
              pull: altPressed ? 'clone' : true,
            }"
            :animation="150"
            item-key="id"
            class="tasks">
            <template #item="{ element: task }: { element: Task }">
              <div>
                <TaskCard :task="task" />
              </div>
            </template>
          </draggable>
        </div>
      </template>
    </draggable>
  </div>
</template>

<script setup lang="ts">
  import { useKeyModifier } from '@vueuse/core'
  import { nanoid } from 'nanoid'

  import { useI18n } from 'vue-i18n'
  // import draggable from 'vuedraggable'

  import { Column, Task } from '@/types/tasks'

  const i18n = useI18n()

  const altPressed = useKeyModifier('Alt')

  const columns = ref<Column[]>([
    {
      id: nanoid(),
      title: 'Überfällig',
      tasks: [
        {
          id: nanoid(),
          title: 'Task 1',
          description: 'Description 1',
          createdAt: new Date(),
        },
        {
          id: nanoid(),
          title: 'Task 2',
          description: 'Description 2',
          createdAt: new Date(),
        },
      ],
    },
    {
      id: nanoid(),
      title: 'Diese Woche',
      tasks: [
        {
          id: nanoid(),
          title: 'Task 3',
          description: 'Description 3',
          createdAt: new Date(),
        },
      ],
    },
    {
      id: nanoid(),
      title: 'Nächster Monat',
      tasks: [
        {
          id: nanoid(),
          title: 'Task 4',
          description: 'Description 4',
          createdAt: new Date(),
        },
      ],
    },
    {
      id: nanoid(),
      title: 'Done',
      tasks: [],
    },
    {
      id: nanoid(),
      title: 'On Hold',
      tasks: [],
    },
  ])
</script>

<style lang="scss" scoped>
  .kanban-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    gap: 10px;
    border-bottom: theme('colors.gray[200]') 1px solid;
    margin-bottom: 10px;
  }
  .kanban {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;

    height: calc(100% - 3rem); // 3rem = padding

    flex: 1;

    overflow-x: auto;
  }
  .column-wrapper {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    overflow-y: hidden;
    align-items: flex-start; // So they have different heights
    flex: 1;
  }
  .column {
    min-width: 300px;
    margin: 0 10px;
    height: 100%;
    max-height: 100%;

    display: flex;
    flex-direction: column;
  }

  .tasks {
    display: flex;
    flex-direction: column;
    gap: 10px;

    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }

  :deep(.task) {
    display: flex;
    flex-direction: column;
    gap: 8px;

    padding: 10px;
    border-radius: var(--border-radius-base);
    background-color: #fff;
    border: theme('colors.gray[200]') 1px solid;
  }

  .task-add {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px;

    margin-bottom: 10px;
  }

  // Header
  .column-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 100%;

    margin-bottom: 10px;
    margin-top: 10px;

    cursor: move;
  }

  .column-status-indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--sm-primary);
  }

  .column-menu {
    margin-left: auto;
    cursor: pointer;
  }
</style>

<style lang="scss">
  .sortable-chosen {
    cursor: grabbing !important;
  }

  // When dragging
  .sortable-drag > * > .task {
    cursor: grabbing !important;
    transform: rotate(4deg);
  }

  // The placeholder
  .sortable-ghost {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      background-color: var(--el-bg-color-page);
      border-radius: var(--border-radius-base);

      // Border
      border: theme('colors.gray[200]') 1px dashed;
    }
  }
</style>
