/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LicenceFilter } from '../models/LicenceFilter';
import type { LicenceOut } from '../models/LicenceOut';
import type { LicenceStatus } from '../models/LicenceStatus';
import type { SoftwareMiniOut } from '../models/SoftwareMiniOut';
import type { SoftwareOut } from '../models/SoftwareOut';
import type { SoftwareTypes } from '../models/SoftwareTypes';
import type { SoftwareUpdateIn } from '../models/SoftwareUpdateIn';
import type { SoftwareUserCount } from '../models/SoftwareUserCount';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SoftwareService {
    /**
     * Get Software List
     * Get information about all software of a company.
     * If no software is found, an empty list is returned.
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getSoftwareListApiV1SoftwareSoftwareGet({
        extended = true,
        tagsExists,
        tagsEq,
        tagsGt,
        tagsGte,
        tagsIn,
        tagsLt,
        tagsLte,
        tagsNe,
        tagsNin,
        tagsAll,
        tagsSize,
        tags,
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        softwareNameExists,
        softwareNameEq,
        softwareNameGt,
        softwareNameGte,
        softwareNameIn,
        softwareNameLt,
        softwareNameLte,
        softwareNameNe,
        softwareNameNin,
        softwareName,
        typeExists,
        typeEq,
        typeGt,
        typeGte,
        typeIn,
        typeLt,
        typeLte,
        typeNe,
        typeNin,
        type,
        authenticationExpiredExists,
        authenticationExpiredEq,
        authenticationExpiredGt,
        authenticationExpiredGte,
        authenticationExpiredIn,
        authenticationExpiredLt,
        authenticationExpiredLte,
        authenticationExpiredNe,
        authenticationExpiredNin,
        authenticationExpired,
        xTransactionId,
    }: {
        /**
         * If `true`, returns an array of `SoftwareOut`.<br>If `false`, returns an array of `SoftwareMiniOut`.
         */
        extended?: boolean,
        tagsExists?: (boolean | null),
        tagsEq?: (string | null),
        tagsGt?: (string | null),
        tagsGte?: (string | null),
        tagsIn?: (Array<string> | null),
        tagsLt?: (string | null),
        tagsLte?: (string | null),
        tagsNe?: (string | null),
        tagsNin?: (Array<string> | null),
        tagsAll?: (Array<string> | null),
        tagsSize?: (number | null),
        tags?: (string | null),
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        softwareNameExists?: (boolean | null),
        softwareNameEq?: (string | null),
        softwareNameGt?: (string | null),
        softwareNameGte?: (string | null),
        softwareNameIn?: (Array<string> | null),
        softwareNameLt?: (string | null),
        softwareNameLte?: (string | null),
        softwareNameNe?: (string | null),
        softwareNameNin?: (Array<string> | null),
        softwareName?: (string | null),
        typeExists?: (boolean | null),
        typeEq?: (SoftwareTypes | null),
        typeGt?: (SoftwareTypes | null),
        typeGte?: (SoftwareTypes | null),
        typeIn?: (Array<SoftwareTypes> | null),
        typeLt?: (SoftwareTypes | null),
        typeLte?: (SoftwareTypes | null),
        typeNe?: (SoftwareTypes | null),
        typeNin?: (Array<SoftwareTypes> | null),
        type?: (SoftwareTypes | null),
        authenticationExpiredExists?: (boolean | null),
        authenticationExpiredEq?: (boolean | null),
        authenticationExpiredGt?: (boolean | null),
        authenticationExpiredGte?: (boolean | null),
        authenticationExpiredIn?: (Array<boolean> | null),
        authenticationExpiredLt?: (boolean | null),
        authenticationExpiredLte?: (boolean | null),
        authenticationExpiredNe?: (boolean | null),
        authenticationExpiredNin?: (Array<boolean> | null),
        authenticationExpired?: (boolean | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<Array<(SoftwareMiniOut | SoftwareOut)>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/software/software',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'extended': extended,
                'tags.$exists': tagsExists,
                'tags.$eq': tagsEq,
                'tags.$gt': tagsGt,
                'tags.$gte': tagsGte,
                'tags.$in': tagsIn,
                'tags.$lt': tagsLt,
                'tags.$lte': tagsLte,
                'tags.$ne': tagsNe,
                'tags.$nin': tagsNin,
                'tags.$all': tagsAll,
                'tags.$size': tagsSize,
                'tags': tags,
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'software_name.$exists': softwareNameExists,
                'software_name.$eq': softwareNameEq,
                'software_name.$gt': softwareNameGt,
                'software_name.$gte': softwareNameGte,
                'software_name.$in': softwareNameIn,
                'software_name.$lt': softwareNameLt,
                'software_name.$lte': softwareNameLte,
                'software_name.$ne': softwareNameNe,
                'software_name.$nin': softwareNameNin,
                'software_name': softwareName,
                'type.$exists': typeExists,
                'type.$eq': typeEq,
                'type.$gt': typeGt,
                'type.$gte': typeGte,
                'type.$in': typeIn,
                'type.$lt': typeLt,
                'type.$lte': typeLte,
                'type.$ne': typeNe,
                'type.$nin': typeNin,
                'type': type,
                'authentication_expired.$exists': authenticationExpiredExists,
                'authentication_expired.$eq': authenticationExpiredEq,
                'authentication_expired.$gt': authenticationExpiredGt,
                'authentication_expired.$gte': authenticationExpiredGte,
                'authentication_expired.$in': authenticationExpiredIn,
                'authentication_expired.$lt': authenticationExpiredLt,
                'authentication_expired.$lte': authenticationExpiredLte,
                'authentication_expired.$ne': authenticationExpiredNe,
                'authentication_expired.$nin': authenticationExpiredNin,
                'authentication_expired': authenticationExpired,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Count Software
     * @returns number Successful Response
     * @throws ApiError
     */
    public static countSoftwareApiV1SoftwareSoftwareCountGet({
        tagsExists,
        tagsEq,
        tagsGt,
        tagsGte,
        tagsIn,
        tagsLt,
        tagsLte,
        tagsNe,
        tagsNin,
        tagsAll,
        tagsSize,
        tags,
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        softwareNameExists,
        softwareNameEq,
        softwareNameGt,
        softwareNameGte,
        softwareNameIn,
        softwareNameLt,
        softwareNameLte,
        softwareNameNe,
        softwareNameNin,
        softwareName,
        typeExists,
        typeEq,
        typeGt,
        typeGte,
        typeIn,
        typeLt,
        typeLte,
        typeNe,
        typeNin,
        type,
        authenticationExpiredExists,
        authenticationExpiredEq,
        authenticationExpiredGt,
        authenticationExpiredGte,
        authenticationExpiredIn,
        authenticationExpiredLt,
        authenticationExpiredLte,
        authenticationExpiredNe,
        authenticationExpiredNin,
        authenticationExpired,
        xTransactionId,
    }: {
        tagsExists?: (boolean | null),
        tagsEq?: (string | null),
        tagsGt?: (string | null),
        tagsGte?: (string | null),
        tagsIn?: (Array<string> | null),
        tagsLt?: (string | null),
        tagsLte?: (string | null),
        tagsNe?: (string | null),
        tagsNin?: (Array<string> | null),
        tagsAll?: (Array<string> | null),
        tagsSize?: (number | null),
        tags?: (string | null),
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        softwareNameExists?: (boolean | null),
        softwareNameEq?: (string | null),
        softwareNameGt?: (string | null),
        softwareNameGte?: (string | null),
        softwareNameIn?: (Array<string> | null),
        softwareNameLt?: (string | null),
        softwareNameLte?: (string | null),
        softwareNameNe?: (string | null),
        softwareNameNin?: (Array<string> | null),
        softwareName?: (string | null),
        typeExists?: (boolean | null),
        typeEq?: (SoftwareTypes | null),
        typeGt?: (SoftwareTypes | null),
        typeGte?: (SoftwareTypes | null),
        typeIn?: (Array<SoftwareTypes> | null),
        typeLt?: (SoftwareTypes | null),
        typeLte?: (SoftwareTypes | null),
        typeNe?: (SoftwareTypes | null),
        typeNin?: (Array<SoftwareTypes> | null),
        type?: (SoftwareTypes | null),
        authenticationExpiredExists?: (boolean | null),
        authenticationExpiredEq?: (boolean | null),
        authenticationExpiredGt?: (boolean | null),
        authenticationExpiredGte?: (boolean | null),
        authenticationExpiredIn?: (Array<boolean> | null),
        authenticationExpiredLt?: (boolean | null),
        authenticationExpiredLte?: (boolean | null),
        authenticationExpiredNe?: (boolean | null),
        authenticationExpiredNin?: (Array<boolean> | null),
        authenticationExpired?: (boolean | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/software/software/count',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'tags.$exists': tagsExists,
                'tags.$eq': tagsEq,
                'tags.$gt': tagsGt,
                'tags.$gte': tagsGte,
                'tags.$in': tagsIn,
                'tags.$lt': tagsLt,
                'tags.$lte': tagsLte,
                'tags.$ne': tagsNe,
                'tags.$nin': tagsNin,
                'tags.$all': tagsAll,
                'tags.$size': tagsSize,
                'tags': tags,
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'software_name.$exists': softwareNameExists,
                'software_name.$eq': softwareNameEq,
                'software_name.$gt': softwareNameGt,
                'software_name.$gte': softwareNameGte,
                'software_name.$in': softwareNameIn,
                'software_name.$lt': softwareNameLt,
                'software_name.$lte': softwareNameLte,
                'software_name.$ne': softwareNameNe,
                'software_name.$nin': softwareNameNin,
                'software_name': softwareName,
                'type.$exists': typeExists,
                'type.$eq': typeEq,
                'type.$gt': typeGt,
                'type.$gte': typeGte,
                'type.$in': typeIn,
                'type.$lt': typeLt,
                'type.$lte': typeLte,
                'type.$ne': typeNe,
                'type.$nin': typeNin,
                'type': type,
                'authentication_expired.$exists': authenticationExpiredExists,
                'authentication_expired.$eq': authenticationExpiredEq,
                'authentication_expired.$gt': authenticationExpiredGt,
                'authentication_expired.$gte': authenticationExpiredGte,
                'authentication_expired.$in': authenticationExpiredIn,
                'authentication_expired.$lt': authenticationExpiredLt,
                'authentication_expired.$lte': authenticationExpiredLte,
                'authentication_expired.$ne': authenticationExpiredNe,
                'authentication_expired.$nin': authenticationExpiredNin,
                'authentication_expired': authenticationExpired,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Software
     * @returns SoftwareOut Successful Response
     * @throws ApiError
     */
    public static getSoftwareApiV1SoftwareSoftwareSoftwareIdGet({
        softwareId,
        cached = true,
        includeDeleted = false,
        xTransactionId,
    }: {
        softwareId: string,
        cached?: boolean,
        includeDeleted?: boolean,
        xTransactionId?: (string | null),
    }): CancelablePromise<SoftwareOut> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/software/software/{software_id}',
            path: {
                'software_id': softwareId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'cached': cached,
                'include_deleted': includeDeleted,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Put Software
     * @returns SoftwareOut Successful Response
     * @throws ApiError
     */
    public static putSoftwareApiV1SoftwareSoftwareSoftwareIdPut({
        softwareId,
        requestBody,
        xTransactionId,
    }: {
        softwareId: string,
        requestBody: SoftwareUpdateIn,
        xTransactionId?: (string | null),
    }): CancelablePromise<SoftwareOut> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/software/software/{software_id}',
            path: {
                'software_id': softwareId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Software
     * @returns void
     * @throws ApiError
     */
    public static deleteSoftwareApiV1SoftwareSoftwareSoftwareIdDelete({
        softwareId,
        xTransactionId,
    }: {
        softwareId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/software/software/{software_id}',
            path: {
                'software_id': softwareId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Fetch Licence Statistics
     * Refreshes the licence statistics for a software and returns the new statistics
     * Note: This is periodically done by the service runner
     * @returns LicenceOut Successful Response
     * @throws ApiError
     */
    public static fetchLicenceStatisticsApiV1SoftwareSoftwareSoftwareIdFetchLicencesStatisticsPost({
        softwareId,
        subAccountId,
        xTransactionId,
    }: {
        softwareId: string,
        subAccountId: string,
        xTransactionId?: (string | null),
    }): CancelablePromise<Array<LicenceOut>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/software/software/{software_id}/fetch-licences-statistics',
            path: {
                'software_id': softwareId,
            },
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'sub_account_id': subAccountId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get User Count
     * @returns SoftwareUserCount Successful Response
     * @throws ApiError
     */
    public static getUserCountApiV1SoftwareUserCountGet({
        includeSubAccounts = true,
        licenceFilter,
        tagsExists,
        tagsEq,
        tagsGt,
        tagsGte,
        tagsIn,
        tagsLt,
        tagsLte,
        tagsNe,
        tagsNin,
        tagsAll,
        tagsSize,
        tags,
        createdExists,
        createdEq,
        createdGt,
        createdGte,
        createdIn,
        createdLt,
        createdLte,
        createdNe,
        createdNin,
        created,
        updatedExists,
        updatedEq,
        updatedGt,
        updatedGte,
        updatedIn,
        updatedLt,
        updatedLte,
        updatedNe,
        updatedNin,
        updated,
        deleted,
        deletedAtExists,
        deletedAtEq,
        deletedAtGt,
        deletedAtGte,
        deletedAtIn,
        deletedAtLt,
        deletedAtLte,
        deletedAtNe,
        deletedAtNin,
        deletedAt,
        includeDeleted = false,
        idExists,
        idEq,
        idGt,
        idGte,
        idIn,
        idLt,
        idLte,
        idNe,
        idNin,
        id,
        softwareIdExists,
        softwareIdEq,
        softwareIdGt,
        softwareIdGte,
        softwareIdIn,
        softwareIdLt,
        softwareIdLte,
        softwareIdNe,
        softwareIdNin,
        softwareId,
        email,
        subAccountIdExists,
        subAccountIdEq,
        subAccountIdGt,
        subAccountIdGte,
        subAccountIdIn,
        subAccountIdLt,
        subAccountIdLte,
        subAccountIdNe,
        subAccountIdNin,
        subAccountId,
        accountIdExists,
        accountIdEq,
        accountIdGt,
        accountIdGte,
        accountIdIn,
        accountIdLt,
        accountIdLte,
        accountIdNe,
        accountIdNin,
        accountId,
        statusExists,
        statusEq,
        statusGt,
        statusGte,
        statusIn,
        statusLt,
        statusLte,
        statusNe,
        statusNin,
        status,
        lastLoginTimeExists,
        lastLoginTimeEq,
        lastLoginTimeGt,
        lastLoginTimeGte,
        lastLoginTimeIn,
        lastLoginTimeLt,
        lastLoginTimeLte,
        lastLoginTimeNe,
        lastLoginTimeNin,
        lastLoginTime,
        licenceGroupsExists,
        licenceGroupsEq,
        licenceGroupsGt,
        licenceGroupsGte,
        licenceGroupsIn,
        licenceGroupsLt,
        licenceGroupsLte,
        licenceGroupsNe,
        licenceGroupsNin,
        licenceGroupsAll,
        licenceGroupsSize,
        licenceGroups,
        isGuestExists,
        isGuestEq,
        isGuestGt,
        isGuestGte,
        isGuestIn,
        isGuestLt,
        isGuestLte,
        isGuestNe,
        isGuestNin,
        isGuest,
        aliasesExists,
        aliasesEq,
        aliasesGt,
        aliasesGte,
        aliasesIn,
        aliasesLt,
        aliasesLte,
        aliasesNe,
        aliasesNin,
        aliases,
        createdExternalExists,
        createdExternalEq,
        createdExternalGt,
        createdExternalGte,
        createdExternalIn,
        createdExternalLt,
        createdExternalLte,
        createdExternalNe,
        createdExternalNin,
        createdExternal,
        nameExists,
        nameEq,
        nameGt,
        nameGte,
        nameIn,
        nameLt,
        nameLte,
        nameNe,
        nameNin,
        name,
        firstNameExists,
        firstNameEq,
        firstNameGt,
        firstNameGte,
        firstNameIn,
        firstNameLt,
        firstNameLte,
        firstNameNe,
        firstNameNin,
        firstName,
        lastNameExists,
        lastNameEq,
        lastNameGt,
        lastNameGte,
        lastNameIn,
        lastNameLt,
        lastNameLte,
        lastNameNe,
        lastNameNin,
        lastName,
        xTransactionId,
    }: {
        includeSubAccounts?: boolean,
        licenceFilter?: LicenceFilter,
        tagsExists?: (boolean | null),
        tagsEq?: (string | null),
        tagsGt?: (string | null),
        tagsGte?: (string | null),
        tagsIn?: (Array<string> | null),
        tagsLt?: (string | null),
        tagsLte?: (string | null),
        tagsNe?: (string | null),
        tagsNin?: (Array<string> | null),
        tagsAll?: (Array<string> | null),
        tagsSize?: (number | null),
        tags?: (string | null),
        createdExists?: (boolean | null),
        createdEq?: (string | null),
        createdGt?: (string | null),
        createdGte?: (string | null),
        createdIn?: (Array<string> | null),
        createdLt?: (string | null),
        createdLte?: (string | null),
        createdNe?: (string | null),
        createdNin?: (Array<string> | null),
        created?: (string | null),
        updatedExists?: (boolean | null),
        updatedEq?: (string | null),
        updatedGt?: (string | null),
        updatedGte?: (string | null),
        updatedIn?: (Array<string> | null),
        updatedLt?: (string | null),
        updatedLte?: (string | null),
        updatedNe?: (string | null),
        updatedNin?: (Array<string> | null),
        updated?: (string | null),
        deleted?: (boolean | null),
        deletedAtExists?: (boolean | null),
        deletedAtEq?: (string | null),
        deletedAtGt?: (string | null),
        deletedAtGte?: (string | null),
        deletedAtIn?: (Array<string> | null),
        deletedAtLt?: (string | null),
        deletedAtLte?: (string | null),
        deletedAtNe?: (string | null),
        deletedAtNin?: (Array<string> | null),
        deletedAt?: (string | null),
        includeDeleted?: boolean,
        idExists?: (boolean | null),
        idEq?: (string | null),
        idGt?: (string | null),
        idGte?: (string | null),
        idIn?: (Array<string> | null),
        idLt?: (string | null),
        idLte?: (string | null),
        idNe?: (string | null),
        idNin?: (Array<string> | null),
        id?: (string | null),
        softwareIdExists?: (boolean | null),
        softwareIdEq?: (string | null),
        softwareIdGt?: (string | null),
        softwareIdGte?: (string | null),
        softwareIdIn?: (Array<string> | null),
        softwareIdLt?: (string | null),
        softwareIdLte?: (string | null),
        softwareIdNe?: (string | null),
        softwareIdNin?: (Array<string> | null),
        softwareId?: (string | null),
        email?: (string | null),
        subAccountIdExists?: (boolean | null),
        subAccountIdEq?: (string | null),
        subAccountIdGt?: (string | null),
        subAccountIdGte?: (string | null),
        subAccountIdIn?: (Array<string> | null),
        subAccountIdLt?: (string | null),
        subAccountIdLte?: (string | null),
        subAccountIdNe?: (string | null),
        subAccountIdNin?: (Array<string> | null),
        subAccountId?: (string | null),
        accountIdExists?: (boolean | null),
        accountIdEq?: (string | null),
        accountIdGt?: (string | null),
        accountIdGte?: (string | null),
        accountIdIn?: (Array<string> | null),
        accountIdLt?: (string | null),
        accountIdLte?: (string | null),
        accountIdNe?: (string | null),
        accountIdNin?: (Array<string> | null),
        accountId?: (string | null),
        statusExists?: (boolean | null),
        statusEq?: (LicenceStatus | null),
        statusGt?: (LicenceStatus | null),
        statusGte?: (LicenceStatus | null),
        statusIn?: (Array<LicenceStatus> | null),
        statusLt?: (LicenceStatus | null),
        statusLte?: (LicenceStatus | null),
        statusNe?: (LicenceStatus | null),
        statusNin?: (Array<LicenceStatus> | null),
        status?: (LicenceStatus | null),
        lastLoginTimeExists?: (boolean | null),
        lastLoginTimeEq?: (string | null),
        lastLoginTimeGt?: (string | null),
        lastLoginTimeGte?: (string | null),
        lastLoginTimeIn?: (Array<string> | null),
        lastLoginTimeLt?: (string | null),
        lastLoginTimeLte?: (string | null),
        lastLoginTimeNe?: (string | null),
        lastLoginTimeNin?: (Array<string> | null),
        lastLoginTime?: (string | null),
        licenceGroupsExists?: (boolean | null),
        licenceGroupsEq?: (string | null),
        licenceGroupsGt?: (string | null),
        licenceGroupsGte?: (string | null),
        licenceGroupsIn?: (Array<string> | null),
        licenceGroupsLt?: (string | null),
        licenceGroupsLte?: (string | null),
        licenceGroupsNe?: (string | null),
        licenceGroupsNin?: (Array<string> | null),
        licenceGroupsAll?: (Array<string> | null),
        licenceGroupsSize?: (number | null),
        licenceGroups?: (string | null),
        isGuestExists?: (boolean | null),
        isGuestEq?: (boolean | null),
        isGuestGt?: (boolean | null),
        isGuestGte?: (boolean | null),
        isGuestIn?: (Array<boolean> | null),
        isGuestLt?: (boolean | null),
        isGuestLte?: (boolean | null),
        isGuestNe?: (boolean | null),
        isGuestNin?: (Array<boolean> | null),
        isGuest?: (boolean | null),
        aliasesExists?: (boolean | null),
        aliasesEq?: (string | null),
        aliasesGt?: (string | null),
        aliasesGte?: (string | null),
        aliasesIn?: (Array<string> | null),
        aliasesLt?: (string | null),
        aliasesLte?: (string | null),
        aliasesNe?: (string | null),
        aliasesNin?: (Array<string> | null),
        aliases?: (string | null),
        createdExternalExists?: (boolean | null),
        createdExternalEq?: (string | null),
        createdExternalGt?: (string | null),
        createdExternalGte?: (string | null),
        createdExternalIn?: (Array<string> | null),
        createdExternalLt?: (string | null),
        createdExternalLte?: (string | null),
        createdExternalNe?: (string | null),
        createdExternalNin?: (Array<string> | null),
        createdExternal?: (string | null),
        nameExists?: (boolean | null),
        nameEq?: (string | null),
        nameGt?: (string | null),
        nameGte?: (string | null),
        nameIn?: (Array<string> | null),
        nameLt?: (string | null),
        nameLte?: (string | null),
        nameNe?: (string | null),
        nameNin?: (Array<string> | null),
        name?: (string | null),
        firstNameExists?: (boolean | null),
        firstNameEq?: (string | null),
        firstNameGt?: (string | null),
        firstNameGte?: (string | null),
        firstNameIn?: (Array<string> | null),
        firstNameLt?: (string | null),
        firstNameLte?: (string | null),
        firstNameNe?: (string | null),
        firstNameNin?: (Array<string> | null),
        firstName?: (string | null),
        lastNameExists?: (boolean | null),
        lastNameEq?: (string | null),
        lastNameGt?: (string | null),
        lastNameGte?: (string | null),
        lastNameIn?: (Array<string> | null),
        lastNameLt?: (string | null),
        lastNameLte?: (string | null),
        lastNameNe?: (string | null),
        lastNameNin?: (Array<string> | null),
        lastName?: (string | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<Record<string, SoftwareUserCount>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/software/user_count',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'include_sub_accounts': includeSubAccounts,
                'licence_filter': licenceFilter,
                'tags.$exists': tagsExists,
                'tags.$eq': tagsEq,
                'tags.$gt': tagsGt,
                'tags.$gte': tagsGte,
                'tags.$in': tagsIn,
                'tags.$lt': tagsLt,
                'tags.$lte': tagsLte,
                'tags.$ne': tagsNe,
                'tags.$nin': tagsNin,
                'tags.$all': tagsAll,
                'tags.$size': tagsSize,
                'tags': tags,
                'created.$exists': createdExists,
                'created.$eq': createdEq,
                'created.$gt': createdGt,
                'created.$gte': createdGte,
                'created.$in': createdIn,
                'created.$lt': createdLt,
                'created.$lte': createdLte,
                'created.$ne': createdNe,
                'created.$nin': createdNin,
                'created': created,
                'updated.$exists': updatedExists,
                'updated.$eq': updatedEq,
                'updated.$gt': updatedGt,
                'updated.$gte': updatedGte,
                'updated.$in': updatedIn,
                'updated.$lt': updatedLt,
                'updated.$lte': updatedLte,
                'updated.$ne': updatedNe,
                'updated.$nin': updatedNin,
                'updated': updated,
                'deleted': deleted,
                'deleted_at.$exists': deletedAtExists,
                'deleted_at.$eq': deletedAtEq,
                'deleted_at.$gt': deletedAtGt,
                'deleted_at.$gte': deletedAtGte,
                'deleted_at.$in': deletedAtIn,
                'deleted_at.$lt': deletedAtLt,
                'deleted_at.$lte': deletedAtLte,
                'deleted_at.$ne': deletedAtNe,
                'deleted_at.$nin': deletedAtNin,
                'deleted_at': deletedAt,
                'include_deleted': includeDeleted,
                '_id.$exists': idExists,
                '_id.$eq': idEq,
                '_id.$gt': idGt,
                '_id.$gte': idGte,
                '_id.$in': idIn,
                '_id.$lt': idLt,
                '_id.$lte': idLte,
                '_id.$ne': idNe,
                '_id.$nin': idNin,
                '_id': id,
                'software_id.$exists': softwareIdExists,
                'software_id.$eq': softwareIdEq,
                'software_id.$gt': softwareIdGt,
                'software_id.$gte': softwareIdGte,
                'software_id.$in': softwareIdIn,
                'software_id.$lt': softwareIdLt,
                'software_id.$lte': softwareIdLte,
                'software_id.$ne': softwareIdNe,
                'software_id.$nin': softwareIdNin,
                'software_id': softwareId,
                'email': email,
                'sub_account_id.$exists': subAccountIdExists,
                'sub_account_id.$eq': subAccountIdEq,
                'sub_account_id.$gt': subAccountIdGt,
                'sub_account_id.$gte': subAccountIdGte,
                'sub_account_id.$in': subAccountIdIn,
                'sub_account_id.$lt': subAccountIdLt,
                'sub_account_id.$lte': subAccountIdLte,
                'sub_account_id.$ne': subAccountIdNe,
                'sub_account_id.$nin': subAccountIdNin,
                'sub_account_id': subAccountId,
                'account_id.$exists': accountIdExists,
                'account_id.$eq': accountIdEq,
                'account_id.$gt': accountIdGt,
                'account_id.$gte': accountIdGte,
                'account_id.$in': accountIdIn,
                'account_id.$lt': accountIdLt,
                'account_id.$lte': accountIdLte,
                'account_id.$ne': accountIdNe,
                'account_id.$nin': accountIdNin,
                'account_id': accountId,
                'status.$exists': statusExists,
                'status.$eq': statusEq,
                'status.$gt': statusGt,
                'status.$gte': statusGte,
                'status.$in': statusIn,
                'status.$lt': statusLt,
                'status.$lte': statusLte,
                'status.$ne': statusNe,
                'status.$nin': statusNin,
                'status': status,
                'last_login_time.$exists': lastLoginTimeExists,
                'last_login_time.$eq': lastLoginTimeEq,
                'last_login_time.$gt': lastLoginTimeGt,
                'last_login_time.$gte': lastLoginTimeGte,
                'last_login_time.$in': lastLoginTimeIn,
                'last_login_time.$lt': lastLoginTimeLt,
                'last_login_time.$lte': lastLoginTimeLte,
                'last_login_time.$ne': lastLoginTimeNe,
                'last_login_time.$nin': lastLoginTimeNin,
                'last_login_time': lastLoginTime,
                'licence_groups.$exists': licenceGroupsExists,
                'licence_groups.$eq': licenceGroupsEq,
                'licence_groups.$gt': licenceGroupsGt,
                'licence_groups.$gte': licenceGroupsGte,
                'licence_groups.$in': licenceGroupsIn,
                'licence_groups.$lt': licenceGroupsLt,
                'licence_groups.$lte': licenceGroupsLte,
                'licence_groups.$ne': licenceGroupsNe,
                'licence_groups.$nin': licenceGroupsNin,
                'licence_groups.$all': licenceGroupsAll,
                'licence_groups.$size': licenceGroupsSize,
                'licence_groups': licenceGroups,
                'is_guest.$exists': isGuestExists,
                'is_guest.$eq': isGuestEq,
                'is_guest.$gt': isGuestGt,
                'is_guest.$gte': isGuestGte,
                'is_guest.$in': isGuestIn,
                'is_guest.$lt': isGuestLt,
                'is_guest.$lte': isGuestLte,
                'is_guest.$ne': isGuestNe,
                'is_guest.$nin': isGuestNin,
                'is_guest': isGuest,
                'aliases.$exists': aliasesExists,
                'aliases.$eq': aliasesEq,
                'aliases.$gt': aliasesGt,
                'aliases.$gte': aliasesGte,
                'aliases.$in': aliasesIn,
                'aliases.$lt': aliasesLt,
                'aliases.$lte': aliasesLte,
                'aliases.$ne': aliasesNe,
                'aliases.$nin': aliasesNin,
                'aliases': aliases,
                'created_external.$exists': createdExternalExists,
                'created_external.$eq': createdExternalEq,
                'created_external.$gt': createdExternalGt,
                'created_external.$gte': createdExternalGte,
                'created_external.$in': createdExternalIn,
                'created_external.$lt': createdExternalLt,
                'created_external.$lte': createdExternalLte,
                'created_external.$ne': createdExternalNe,
                'created_external.$nin': createdExternalNin,
                'created_external': createdExternal,
                'name.$exists': nameExists,
                'name.$eq': nameEq,
                'name.$gt': nameGt,
                'name.$gte': nameGte,
                'name.$in': nameIn,
                'name.$lt': nameLt,
                'name.$lte': nameLte,
                'name.$ne': nameNe,
                'name.$nin': nameNin,
                'name': name,
                'first_name.$exists': firstNameExists,
                'first_name.$eq': firstNameEq,
                'first_name.$gt': firstNameGt,
                'first_name.$gte': firstNameGte,
                'first_name.$in': firstNameIn,
                'first_name.$lt': firstNameLt,
                'first_name.$lte': firstNameLte,
                'first_name.$ne': firstNameNe,
                'first_name.$nin': firstNameNin,
                'first_name': firstName,
                'last_name.$exists': lastNameExists,
                'last_name.$eq': lastNameEq,
                'last_name.$gt': lastNameGt,
                'last_name.$gte': lastNameGte,
                'last_name.$in': lastNameIn,
                'last_name.$lt': lastNameLt,
                'last_name.$lte': lastNameLte,
                'last_name.$ne': lastNameNe,
                'last_name.$nin': lastNameNin,
                'last_name': lastName,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
