import { defineStore } from 'pinia'
import dayjs from 'dayjs'
import {
  LicenceOut,
  SoftwareOut,
  SoftwareLicense,
  SoftwareLicenseMini,
  QuotaInformation_Output,
  QuotaStatus,
  PriceInformation,
} from '@/client'
import { Document } from '@/types/document'
import { Optimization } from '@/types/optimization'
import { SubAccount } from '@/types/software'
import { Statistic } from '@/types/statistics'
import { licenseStore } from '@/stores/licenseV2.store'
import { SoftwareLicensesFilter } from '@/stores/license.filter'
import { usePriceInformationStore } from '@/stores/priceInformationStore'

export const useApplicationDetailsStore = defineStore(
  'applicationDetailsViewStore',
  () => {
    const software = ref<SoftwareOut>()
    const optimizations = ref<Optimization[]>()
    const documents = ref<Document[]>()
    const costStatistics = ref<Statistic[]>()
    const currentCost = ref<number>()
    const selectedSubAccount = ref<SubAccount>()
    const quota = ref<Record<string, QuotaInformation_Output>>({})
    const licences = ref<SoftwareLicense[]>([])
    const licencesTable = ref<{
      selectedLicences?: LicenceOut[]
      searchQuery?: string
      isLoading: boolean
      onlyShowInvites: boolean
    }>({
      selectedLicences: undefined,
      searchQuery: '',
      isLoading: false,
      onlyShowInvites: false,
    })

    async function initSoftware(loadedSoftware: SoftwareOut) {
      software.value = loadedSoftware
    }

    async function initLicenseModels(
      loadedLicenseModels: { license: SoftwareLicense }[]
    ) {
      const transformedLicenses = loadedLicenseModels.map(
        (item) => item.license
      )
      licences.value = transformedLicenses
    }

    async function loadQuota(licenseId: string) {
      const priceInformationStore = usePriceInformationStore()
      const activePriceInformations = ref<PriceInformation[]>([])
      let totalQuota = 0

      const cachedPriceInformation = computed(() => {
        return priceInformationStore.getPriceInformation(licenseId)
      })

      if (!cachedPriceInformation.value) {
        await priceInformationStore.loadPriceInformation(licenseId)
      }

      // Add quota from license if nothing is cached
      if (
        cachedPriceInformation.value &&
        Object.keys(cachedPriceInformation.value).length === 0
      ) {
        const license = licences.value.find(
          (license) => license._id === licenseId
        )
        const externalQuota = license?.quota_information

        if (externalQuota) {
          quota.value[licenseId] = externalQuota
        }
      }

      if (!cachedPriceInformation.value) return

      // check which priceInfos are active
      for (const priceInformation of cachedPriceInformation.value) {
        const fromDate = priceInformation.from_date
          ? dayjs(priceInformation.from_date)
          : null
        const endDate = priceInformation.end_date
          ? dayjs(priceInformation.end_date)
          : null

        if (
          (fromDate && endDate && dayjs().isBetween(fromDate, endDate)) ||
          !fromDate ||
          !endDate
        ) {
          activePriceInformations.value.push(priceInformation)
        }
      }

      // sum up all priceInfos
      totalQuota = activePriceInformations.value.reduce(
        (sum, obj) => sum + (obj.quota || 0),
        0
      )

      // count used quotas
      const currentCount = await licenseStore
        .getLicensesCount([new SoftwareLicensesFilter([licenseId])])
        .then((count) => {
          return count
        })

      const quotaInformation: Ref<QuotaInformation_Output> = ref({
        quota: totalQuota,
        used: currentCount,
        available: totalQuota - currentCount,
        status: QuotaStatus.AVAILABLE, // deprecated?
        free_quota: 0, //deprecated?
      })

      if (totalQuota > 0) {
        quota.value[licenseId] = quotaInformation.value
      } else {
        delete quota.value[licenseId]
      }
    }

    async function loadAllQuotas(values: SoftwareLicense[]) {
      for (const key in values) {
        const license: SoftwareLicense = values[key]
        loadQuota(license._id)
      }
    }

    function updateQuota(
      afterValue: Record<string, SoftwareLicenseMini | null> | undefined,
      beforeValue?: Record<string, SoftwareLicenseMini>
    ) {
      for (const id in afterValue) {
        const cachedQuota = quota.value[id]
        const licenseRemoved = afterValue[id] === null
        const licenseExisted = beforeValue && beforeValue[id]

        if (cachedQuota) {
          if (licenseRemoved) {
            cachedQuota.used -= 1
            cachedQuota.available = cachedQuota.quota - cachedQuota.used
          } else if (licenseExisted) {
            // do nothing
          } else {
            cachedQuota.used += 1
            cachedQuota.available = cachedQuota.quota - cachedQuota.used
          }
        }
      }
    }

    function $reset() {
      ;(software.value = undefined),
        (optimizations.value = undefined),
        (documents.value = undefined),
        (costStatistics.value = undefined),
        (currentCost.value = undefined),
        (selectedSubAccount.value = undefined),
        (quota.value = {}),
        (licences.value = []),
        (licencesTable.value = {
          selectedLicences: undefined,
          searchQuery: '',
          isLoading: false,
          onlyShowInvites: false,
        })
    }

    return {
      initSoftware,
      initLicenseModels,
      $reset,
      software,
      optimizations,
      documents,
      costStatistics,
      currentCost,
      selectedSubAccount,
      licences,
      licencesTable,

      // Quota related
      quota,
      loadQuota,
      loadAllQuotas,
      updateQuota,
    }
  }
)
