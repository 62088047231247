import {
  ValidationResult,
  ValidationRule,
} from '@/components/sm/SmInput/SmInputValidator'
import { cloneDeep, isEqual } from 'lodash-es'

/**
 * INTERFACES
 */

export interface Form<T extends object> {
  fields: T
  formHooks: FormHooks
  validator: { [P in keyof T]?: ValidationRule[] }
  fieldNames: { [P in keyof T]?: string }
}

export interface ErrorResponse {
  body: {
    detail: {
      error: string
      message: string
    }
  }
}

export interface Error extends ErrorResponse {
  notificationTitle: string | undefined
  notificationMessage: string
  notificationType: 'error' | 'warning' | 'success' | 'info'
  type: 'error'
}

export interface SubmitHooks {
  onBeforeSubmit?: () => void
  onSuccess?: (response: unknown) => void
  onError?: (error: ErrorResponse | unknown) => void
  onAfterSubmit?: () => void
}

export interface FormHooks {
  onReset?: (fields: string[]) => void
}

/**
 * FUNCTIONS
 */

export default function useForm<T extends object, J>(
  fields: T,
  formHooks: FormHooks = {},
  validator: { [P in keyof T]?: ValidationRule[] } = {} as {
    [P in keyof T]?: ValidationRule[]
  },
  fieldNames = {} as { [P in keyof T]?: string }
) {
  const defaults = fields
  let recentlySusccessfulTimeoutId: NodeJS.Timeout

  const form = reactive({
    fields: cloneDeep(fields),
    errors: {} as Record<keyof T, ValidationResult[]>,
    fieldNames: fieldNames,
    processing: false,
    result: null as J | null,
    dirty: false,
    wasSuccessful: false,
    recentlySuccessful: false,

    validate(fields: string[] = []) {
      Object.keys(validator).forEach((key) => {
        if (!fields.includes(key) && fields.length !== 0) return
        delete this.errors[key as keyof T]

        if (fields.length > 0 && !fields.includes(key)) return

        const rules = validator[key as keyof T] as ValidationRule[]
        const value = this.fields[key as keyof T]

        rules.forEach((rule) => {
          const result = rule(value)
          if (result) {
            this.errors[key as keyof T] = [
              ...(this.errors[key as keyof T] || []),
              result,
            ]
          }
        })
      })
      return Object.keys(this.errors).length === 0
    },

    reset(fields: [] = []) {
      const clonedDefaults = cloneDeep(defaults)

      if (fields.length === 0) {
        this.fields = clonedDefaults
      } else {
        fields.forEach((field) => {
          if (clonedDefaults[field] !== undefined) {
            this.fields[field] = clonedDefaults[field]
          }
        })
      }

      if (formHooks.onReset) formHooks.onReset(fields)
    },

    setError(field: keyof T, error: ValidationResult) {
      this.errors[field] = [error]
    },

    clearError(...fields: (keyof T)[]) {
      if (fields.length === 0) {
        this.errors = {} as Record<keyof T, ValidationResult[]>
      } else {
        fields.forEach((field) => {
          delete this.errors[field]
        })
      }
    },

    async submit(
      submitFunction: (fields: T) => Promise<J | Error>,
      hooks: SubmitHooks = {}
    ) {
      if (this.processing) return

      // Hooks
      const _hooks = {
        onBeforeSubmit: async () => {
          this.errors = {} as Record<keyof T, ValidationResult[]>
          this.processing = true
          this.wasSuccessful = false
          this.recentlySuccessful = false

          clearTimeout(recentlySusccessfulTimeoutId)
          if (hooks.onBeforeSubmit) await hooks.onBeforeSubmit()
        },
        onSuccess: (response: J) => {
          this.result = response
          this.wasSuccessful = true

          this.recentlySuccessful = true

          this.clearError() // Clear all errors
          if (hooks.onSuccess) hooks.onSuccess(response)

          recentlySusccessfulTimeoutId = setTimeout(() => {
            this.recentlySuccessful = false
          }, 2500)
        },
        onError: (error: ErrorResponse | unknown) => {
          if (hooks.onError) hooks.onError(error)
        },
        onAfterSubmit: () => {
          this.processing = false

          if (hooks.onAfterSubmit) hooks.onAfterSubmit()
        },
      }

      // Before
      await _hooks.onBeforeSubmit()

      try {
        const result = await submitFunction(this.fields)
        // Success
        await _hooks.onSuccess(result as J)
      } catch (error: unknown) {
        // Error
        await _hooks.onError(error)
        return this.errors
      } finally {
        // Finally
        await _hooks.onAfterSubmit()
      }
    },
  })

  watch(
    form,
    () => {
      form.dirty = !isEqual(form.fields, defaults)
    },
    { deep: true, immediate: true }
  )

  // Revalidate on change if errors
  watch(
    () => form.fields,
    () => {
      if (form.errors && Object.keys(form.errors).length > 0) form.validate()
    },
    { deep: true, immediate: true }
  )

  return form
}
