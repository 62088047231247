<template>
  <div :class="classes">
    <div style="height: 100%; width: 100%" v-html="getAvatar(name)" />
  </div>
</template>

<script lang="ts" setup>
  import { initials } from '@dicebear/collection'
  import { createAvatar } from '@dicebear/core'

  type AvatarSize = 'small' | 'medium' | 'large'
  type AvatarForm = 'square' | 'rounded' | 'circle'

  const props = defineProps({
    name: {
      type: String,
      required: true,
    },
    size: {
      type: String as PropType<AvatarSize>,
      default: 'medium',
    },
    backgroundColor: {
      type: String,
      default: 'transparent',
    },
    form: {
      type: String as PropType<AvatarForm>,
      default: 'square',
    },
  })

  //   Generates a avatar for items without an image
  function getAvatar(name: string) {
    const avatar = createAvatar(initials, {
      seed: name,
      radius: 10,
      backgroundColor: ['transparent'],
      textColor: ['1b242e'],
      fontFamily: ['Poppins', 'Arial'],
    })
    return avatar
  }

  const classes = computed(() => {
    return {
      'sm-avatar': true,
      [`sm-avatar--${props.size}`]: true,
      [`sm-avatar--${props.form}`]: true,
    }
  })
</script>

<style lang="scss">
  .sm-avatar {
    display: flex;
    flex-direction: row;
    border-radius: var(--border-radius);
    transition: background-color 0.2s ease-in-out;
    background-color: v-bind(backgroundColor);
    border: 1px solid var(--el-border-color);

    &--small {
      width: 40px;
      height: 40px;
    }

    &--medium {
      width: 60px;
      height: 60px;
    }

    &--large {
      width: 80px;
      height: 80px;
    }

    // Forms
    &--square {
      border-radius: var(--border-radius-base);
    }

    &--rounded {
      border-radius: var(--border-radius-rounded);
    }

    &--circle {
      border-radius: 50%;
    }
  }
</style>
