<template>
  <SmDialog v-model:visibility="visibility" title="Alias Löschen">
    <template #default>
      <div class="text-center">
        <p>
          Möchten Sie den Alias
          <b>{{ alias.name }}</b>
          wirklich löschen?
        </p>

        <AliasActionPreviewComponent
          :alias="props.alias.name"
          action="deleted"
          :user="response.user"
          :added-user="response.added_user"
          :deleted-user="response.deleted_user"
          :users="response.users"
          :licences="response.licences" />
      </div>
    </template>
    <template #footer>
      <div style="display: flex; float: right; margin-top: 15px">
        <el-button @click="() => emit('abort')"> Abbrechen </el-button>
        <el-button type="primary" @click="() => emit('confirm')">
          Änderungen übernehmen
        </el-button>
      </div>
    </template>
  </SmDialog>
</template>

<script lang="ts" setup>
  import { AliasType } from '@/types/user'

  const emit = defineEmits(['update:visibility', 'confirm', 'abort'])
  const props = defineProps({
    visibility: {
      type: Boolean,
      default: false,
    },
    alias: {
      type: Object as PropType<AliasType>,
      required: true,
    },
    response: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Object as PropType<any>,
      required: true,
    },
  })

  const visibility = computed({
    get: () => props.visibility,
    set: (value) => emit('update:visibility', value),
  })
</script>
