<template>
  <slot name="selection" :row="props.data">
    <div
      v-if="props.selectable"
      class="table-select"
      @click="handleCellClick(-1, props.data, 'selection')">
      <input
        type="checkbox"
        :checked="isChecked"
        :value="props.data[keyField as string]"
        :disabled="props.selectDisabled"
        @change="(e) => handleSelectionChange(e, props.data)" />
    </div>
  </slot>

  <div v-if="props.draggable" class="handle">
    <v-icon
      name="md-dragindicator"
      scale="1.4"
      hover
      fill="var(--el-text-color-disabled)" />
  </div>

  <div
    v-for="(column, idx) in props.columns"
    :key="column.key"
    :class="[`cell-${column.key}`, 'table-cell']"
    :style="{
      paddingLeft: idx === 0 && !selectable ? '10px' : '0',
      ...cellStyle(column),
    }"
    @click="handleCellClick(idx, props.data, column.key)">
    <slot :name="column.key" :row="props.data">
      <span
        v-if="
          props.data[column.key] && typeof props.data[column.key] === 'string'
        ">
        <TextMultiline
          :text="props.data[column.key] as string"
          tooltip-max-width="300px" />
      </span>
      <span v-else class="no-data">{{ i18n.t('noData') }}</span>
    </slot>
  </div>
</template>

<script setup generic="T extends TableData" lang="ts">
  import { useI18n } from 'vue-i18n'
  import { Column } from './SmTable.types'
  import TextMultiline from './TextMultiline.vue'
  import { TableData } from './SmTable.vue'

  const i18n = useI18n()

  interface Props {
    keyField: keyof T
    data: T
    selectable?: boolean
    selectDisabled?: boolean
    columns: Column<T>[]
    isChecked?: boolean
    draggable?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    selectable: false,
    draggable: false,
    isChecked: false,
  })

  const emit = defineEmits<{
    'handle-cell-click': [{ iRow: number; columnKey: string; row: T }]
    'handle-selection-change': [{ event: Event; item: T }]
  }>()

  function handleCellClick(iRow: number, row: T, columnKey: string) {
    emit('handle-cell-click', { iRow, columnKey, row })
  }

  function handleSelectionChange(event: Event, item: T) {
    emit('handle-selection-change', { event, item })
  }

  const columnStyleCache: Record<string, Record<string, number | string>> = {}
  function cellStyle(column: Column<T>) {
    // Check cache
    if (columnStyleCache[column.key]) {
      return columnStyleCache[column.key]
    }

    const style: Record<string, number | string> = {}

    // Get width
    if (typeof column.width === 'number') {
      style['flex'] = `${column.width}`
    } else {
      style['width'] = column.width
    }

    // Cache style
    if (!columnStyleCache[column.key]) {
      columnStyleCache[column.key] = {}
    }
    columnStyleCache[column.key] = style

    // Return style
    return style
  }
</script>

<style scoped lang="scss">
  $gap: 1.5rem;
  $select-width: 40px;

  .table-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--el-border-color-lighter);
    gap: 0 $gap;
    min-height: 52px;
    cursor: pointer;

    &:hover {
      background-color: var(--el-fill-color-light);
    }
  }

  .table-select {
    width: $select-width;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .table-cell {
    display: flex;
    justify-content: left;
    color: var(--el-table-text-color);
  }
</style>

<style lang="scss">
  .handle {
    cursor: grab !important;
  }

  .table-row.sortable-chosen {
    cursor: grabbing !important;
  }
</style>
