<template>
  <SmDialog v-model:visibility="visible" :title="props.title">
    <div>
      {{ props.subTitle }}
    </div>

    <template #footer>
      <div class="buttons">
        <SmButton outline @click="emit('cancel')">
          {{ i18n.t('general.cancel') }}
        </SmButton>
        <SmButton :loading="props.loading" @click="emit('delete')"
          >
{{ i18n.t('general.delete') }}
        </SmButton>
      </div>
    </template>
  </SmDialog>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  const i18n = useI18n()

  const props = defineProps({
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  })

  const emit = defineEmits(['cancel', 'delete'])

  const visible = defineModel('visible', {
    type: Boolean,
    required: true,
  })
</script>

<style>
  .buttons {
    display: flex;
    gap: 1rem;
    justify-content: center;
  }
</style>
