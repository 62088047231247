/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GraphGroupMethod } from '../models/GraphGroupMethod';
import type { GraphModifierFillMethod } from '../models/GraphModifierFillMethod';
import type { GraphPeriodUnit } from '../models/GraphPeriodUnit';
import type { MetadataQueryField } from '../models/MetadataQueryField';
import type { OptimizationStatus } from '../models/OptimizationStatus';
import type { OptimizationType } from '../models/OptimizationType';
import type { SavingResponse } from '../models/SavingResponse';
import type { SavingType } from '../models/SavingType';
import type { StatisticsDB } from '../models/StatisticsDB';
import type { StatisticType } from '../models/StatisticType';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class StatisticsService {
    /**
     * Get Statistic Raw
     * @returns StatisticsDB Successful Response
     * @throws ApiError
     */
    public static getStatisticRawApiV1StatisticsRawGet({
        fromDate,
        tillDate,
        sorting = 'asc',
        sortingField = 'timestamp',
        type,
        field,
        optimizationType,
        optimizationStatus,
        savingType,
        softwareId,
        xTransactionId,
    }: {
        fromDate?: (string | null),
        tillDate?: (string | null),
        sorting?: 'asc' | 'desc',
        sortingField?: 'timestamp' | 'value',
        type?: (StatisticType | null),
        field?: (MetadataQueryField | null),
        optimizationType?: (OptimizationType | null),
        optimizationStatus?: (OptimizationStatus | null),
        savingType?: (SavingType | null),
        softwareId?: (string | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<Array<StatisticsDB>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/statistics/raw',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'from_date': fromDate,
                'till_date': tillDate,
                'sorting': sorting,
                'sorting_field': sortingField,
                'type': type,
                'field': field,
                'optimization_type': optimizationType,
                'optimization_status': optimizationStatus,
                'saving_type': savingType,
                'software_id': softwareId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Statistic
     * Returns a list of tuples to be displayed in a graph.<br>The first value corresponds to the x-axis and is a timestamp.<br>The second value corresponds to the y-axis and is a floating point value.<br>The filter parameter, e.g. `field`, `type`, should always be set
     * @returns any[] Successful Response
     * @throws ApiError
     */
    public static getStatisticApiV1StatisticsGet({
        fromDate,
        tillDate,
        type,
        field,
        optimizationType,
        optimizationStatus,
        savingType,
        softwareId,
        periodUnit,
        periodStepSize = 1,
        periodStartOfWeek,
        fillMethod,
        groupMethod,
        xTransactionId,
    }: {
        fromDate?: (string | null),
        tillDate?: (string | null),
        type?: (StatisticType | null),
        field?: (MetadataQueryField | null),
        optimizationType?: (OptimizationType | null),
        optimizationStatus?: (OptimizationStatus | null),
        savingType?: (SavingType | null),
        softwareId?: (string | null),
        periodUnit?: GraphPeriodUnit,
        periodStepSize?: number,
        periodStartOfWeek?: number,
        fillMethod?: GraphModifierFillMethod,
        groupMethod?: GraphGroupMethod,
        xTransactionId?: (string | null),
    }): CancelablePromise<Array<any[]>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/statistics/',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'from_date': fromDate,
                'till_date': tillDate,
                'type': type,
                'field': field,
                'optimization_type': optimizationType,
                'optimization_status': optimizationStatus,
                'saving_type': savingType,
                'software_id': softwareId,
                'period_unit': periodUnit,
                'period_step_size': periodStepSize,
                'period_start_of_week': periodStartOfWeek,
                'fill_method': fillMethod,
                'group_method': groupMethod,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Savings
     * @returns SavingResponse Successful Response
     * @throws ApiError
     */
    public static getSavingsApiV1StatisticsSavingsGet({
        softwareId,
        xTransactionId,
    }: {
        softwareId?: (string | null),
        xTransactionId?: (string | null),
    }): CancelablePromise<SavingResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/statistics/savings',
            headers: {
                'x-transaction-id': xTransactionId,
            },
            query: {
                'software_id': softwareId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
