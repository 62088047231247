<template>
  <DocumentUploadDialog
    v-if="createDocumentDrawerVisibility"
    v-model:show-drawer="createDocumentDrawerVisibility"
    :edit-mode="false"
    :software-id="null"
    view-mode
    @upload-document-success="updateDocumentList()" />

  <el-scrollbar height="calc(100% - 35px)">
    <ListItem
      v-for="(document, index) in documents"
      :key="document._id"
      :document="document"
      :current-index="index"
      :documents-count="documents?.length || 0" />

    <!-- Add license -->
    <!-- Plus left and text -->
    <div
      v-if="$props.showAddRow"
      class="add-row"
      @click="createDocumentDrawerVisibility = true">
      <v-icon name="md-add-round" scale="1.1" />
      <span>{{ i18n.t('addDocument') }}</span>
    </div>
  </el-scrollbar>
</template>

<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'

  import { DocumentInfo, DocumentsService } from '@/client'
  import DocumentUploadDialog from '@/components/DocumentUploadDialog.vue'

  import ListItem from './DocumentCardDocumentsListItem.vue'

  const i18n = useI18n()

  const documents = ref<Array<DocumentInfo> | null>(null)
  const loading = ref(false)

  const createDocumentDrawerVisibility = ref(false)

  const props = defineProps({
    softwareId: {
      type: String,
      required: true,
    },
    showAddRow: {
      type: Boolean,
      default: false,
    },
  })

  onMounted(() => {
    watch(
      () => props.softwareId,
      async () => {
        await updateDocumentList()
      },
      { immediate: true }
    )
  })

  async function updateDocumentList() {
    documents.value = null
    loading.value = true
    documents.value =
      await DocumentsService.getDocumentInfoListApiV1SoftwareDocumentsGet({
        softwareId: props.softwareId,
      })
  }
</script>

<style lang="scss" scoped>
  .add-row {
    border-top: 1px solid var(--el-border-color-lighter);

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;

    padding: 1rem 0;
    font-size: 1.1rem;

    cursor: pointer;
  }
</style>
