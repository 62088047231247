import { useDark } from '@vueuse/core'
import { defineStore } from 'pinia'

import {
  Account,
  CompanyData,
  CompanyManagementService,
  OpenAPI,
} from '@/client'
import { SubscriptionInfo } from '@/types/billing'

export enum Mode {
  Demo = 'demo', // if the user is in demo-data mode
  Trial = 'trial',
  Paid = 'paid',
  Default = 'default',
  Dev = 'dev',
}

type TutorialType = {
  maxStepCount: number
  currentSectionIndex: number
  currentStepIndex: number
}

type GlobalStore = {
  uploadEndpoint: string
  productionApi: string
  stagingApi: string
  developmentApi: string
  betaApi: string

  mode: Mode[]

  companyInfo: CompanyData | null

  account: Account | null

  // Dialogs
  showOnboardingDialog: boolean
  showAuthFlowDialog: boolean
  showReauthenticateDialog: boolean

  // Current Popup
  currentPopup: string | null

  // Debugging
  showDebugModal: boolean
  showDebugModalFPS: boolean

  // Colors
  colors: {
    green: string
    'green-trans': string
    red: string[]
    'red-trans': string[]
    blue: string[]
    'blue-trans': string[]
    mixed: string[]
    'mixed-trans': string[]
    grey: string
    'grey-trans': string
    'grey-dark': string
  }

  // Icons
  iconMapping: {
    user: string
    licenses: string
    optimizations: string
    applications: string
    version: string
    delete: string
  }

  // User related
  user: {
    jwt?: string
    user?: Account
    tutorial?: TutorialType
    language: string
    subscription?: SubscriptionInfo
  }

  // Settings related
  settings: {
    isDark: boolean
  }
}

const isDark = useDark()

export const useGlobalStore = defineStore('globalStore', {
  state: (): GlobalStore => ({
    // Connection data
    uploadEndpoint: 'https://vps1.saasmetrix.de:2100/file-upload',
    productionApi: 'https://api.saasmetrix.io',
    stagingApi: 'https://api.staging.intern.saasmetrix.io',
    developmentApi: 'https://dev.saasmetrix.io',
    betaApi: 'https://beta-api.saasmetrix.io',

    // Dialogs
    showOnboardingDialog: false,
    showDebugModal: false,
    showDebugModalFPS: false,
    showAuthFlowDialog: false,
    showReauthenticateDialog: false,

    mode: [Mode.Default],

    companyInfo: null,

    // Current Account
    account: null,

    // Colors
    colors: colors,

    // Icons
    iconMapping: iconMapping,

    // Current Popup
    currentPopup: null,

    // User related
    user: {
      jwt: undefined,
      language: 'de',
    },

    // Settings related
    settings: {
      isDark: isDark.value,
    },
  }),
  getters: {
    state: (state) => state,
    getIp: (state) => {
      switch (import.meta.env.MODE) {
        case 'development':
          return state.developmentApi
        case 'production':
          return state.productionApi
        case 'staging':
          return state.stagingApi
        case 'beta':
          return state.productionApi
        case 'localhost':
          return 'http://127.0.0.1:8000'
        default:
          return 'http://127.0.0.1:8000'
      }
    },
  },
  actions: {
    // TODO: Remove this
    getJWT(): string | undefined {
      if (OpenAPI.TOKEN) return OpenAPI.TOKEN as string
      return undefined
    },

    async refetchCompanyInfo(): Promise<CompanyData> {
      return await CompanyManagementService.getCompanyInfoApiV1CompanyInfoGet(
        {}
      ).then((companyInfo) => {
        this.companyInfo = companyInfo
        return companyInfo
      })
    },

    setTutorial(tutorial: TutorialType | undefined): void {
      this.user.tutorial = tutorial
    },
    setIsDark(value: boolean): void {
      this.settings.isDark = value
    },
  },
})

const colors = {
  green: '#08e9c5',
  'green-trans': 'rgba(8, 233, 197, 0.6)',
  red: [
    'rgb(245, 108, 108)',
    'rgb(211, 96, 137)',
    'rgb(161, 94, 150)',
    'rgb(108, 92, 144)',
    'rgb(66, 85, 120)',
  ],
  'red-trans': [
    'rgba(245, 108, 108, 0.6)',
    'rgb(211, 96, 137, 0.6)',
    'rgb(161, 94, 150, 0.6)',
    'rgb(108, 92, 144, 0.6)',
    'rgb(66, 85, 120, 0.6)',
  ],
  blue: [
    'rgb(8, 233, 197)',
    'rgb(0, 200, 191)',
    'rgb(0, 166, 176)',
    'rgb(1, 133, 153)',
    'rgb(40, 101, 123)',
  ],
  'blue-trans': [
    'rgba(8, 233, 197, 0.6)',
    'rgba(0, 200, 191, 0.6)',
    'rgba(0, 166, 176, 0.6)',
    'rgba(1, 133, 153, 0.6)',
    'rgba(40, 101, 123, 0.6)',
  ],
  mixed: ['rgb(8, 233, 197)', 'rgb(0, 107, 150)', 'rgb(245, 108, 108)'],
  'mixed-trans': [
    'rgba(8, 233, 197, 0.6)',
    'rgba(0, 107, 150, 0.6)',
    'rgba(245, 108, 108, 0.6)',
  ],
  grey: 'rgba(220, 220, 220, 1)',
  'grey-trans': 'rgba(220, 220, 220, 0.4)',
  'grey-dark': 'rgba(100, 100, 100, 1)',
}

const iconMapping = {
  user: 'io-person',
  licenses: 'io-key',
  optimizations: 'io-sparkles',
  applications: 'io-cube',
  version: 'io-hammer',
  delete: 'md-delete-round',
}
