<template>
  <SmTooltip>
    <template #content>
      <div style="display: grid; grid-template-columns: auto auto; gap: 0 10px">
        <!-- Exceeded Quota -->
        <template v-if="quotaExceeded">
          <p class="col-span-2 pb-2 font-bold">
            {{ i18n.t('exceededQuota') }}
          </p>
          <p>{{ i18n.t('available') }}:</p>
          <p>
            {{ props.quota_information?.quota }}
          </p>
          <p>{{ i18n.t('used') }}:</p>
          <p>
            {{ props.quota_information?.used }}
          </p>
        </template>

        <!-- Quotainfo -->
        <template v-else>
          <span>{{ i18n.t('quota') }}:</span>
          <span>{{ props.quota_information?.quota }}</span>
          <span>{{ i18n.t('used') }}:</span>
          <span>{{ props.quota_information?.used }}</span>
          <span>{{ i18n.t('remaining') }}:</span>
          <span>{{ props.quota_information?.available }}</span>
        </template>
      </div>
    </template>

    <SmProgressBar
      :value="props.quota_information?.used"
      :max="props.quota_information?.quota"
      :color="quotaExceeded ? 'var(--sm-red)' : undefined" />
  </SmTooltip>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { QuotaInformation_Input } from '@/client'

  const i18n = useI18n()

  // Props
  export type Props = {
    quota_information: QuotaInformation_Input | null
  }

  const props = withDefaults(defineProps<Props>(), {
    quota_information: null,
  })

  const quotaExceeded = computed(() => {
    if (!props.quota_information) return false

    return props.quota_information?.used > props.quota_information?.quota
  })
</script>

<style lang="scss"></style>
