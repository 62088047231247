import { useColorMode } from '@vueuse/core'

function applyColorMode(isDark: boolean) {
  const mode = useColorMode()
  mode.value = isDark ? 'dark' : 'light'
}

function applyLanguage(language: string) {
  // TODO: Implement language change
}

export function applyPreference(
  preference: string,
  value: string | number | boolean | unknown
) {
  switch (preference) {
    case 'isDark':
      return applyColorMode(value as boolean)
    case 'language':
      return applyLanguage(value as string)
    default:
      return
  }
}
