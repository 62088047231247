<template>
  <SmDialog
    v-model:visibility="visibility"
    :title="
      alias == null
        ? i18n.t(
            'drawers.userDetailDrawer.aliasesCard.dialogs.aliasCreate.title.create'
          )
        : i18n.t(
            'drawers.userDetailDrawer.aliasesCard.dialogs.aliasCreate.title.edit'
          )
    ">
    <el-form
      ref="formRef"
      label-position="top"
      :model="formData"
      :rules="formRules">
      <el-form-item
        prop="name"
        :label="
          i18n.t(
            'drawers.userDetailDrawer.aliasesCard.dialogs.aliasCreate.form.name.label'
          )
        ">
        <el-input
          v-model="formData.name"
          :placeholder="i18n.t('general.name')"
          :disabled="alias?.name != undefined" />
      </el-form-item>
      <el-form-item
        prop="description"
        :label="
          i18n.t(
            'drawers.userDetailDrawer.aliasesCard.dialogs.aliasCreate.form.description.label'
          )
        ">
        <el-input
          v-model="formData.description"
          :placeholder="i18n.t('general.description')" />
      </el-form-item>
    </el-form>

    <div style="margin-bottom: 15px">
      <AliasActionPreviewComponent
        v-if="wasPreviewed && createAliasResponse != null"
        :alias="formData.name"
        action="created"
        :user="createAliasResponse.user"
        :added-user="createAliasResponse.added_user"
        :deleted-user="createAliasResponse.deleted_user"
        :users="createAliasResponse.users"
        :licences="createAliasResponse.licences" />
    </div>
    <template #footer>
      <div style="display: flex; flex-direction: row">
        <SmButton outline @click="visibility = false">
          {{ i18n.t('general.cancel') }}
        </SmButton>
        <SmButton type="primary" @click="saveAlias">
          {{
            wasPreviewed
              ? i18n.t(
                  'drawers.userDetailDrawer.aliasesCard.dialogs.aliasCreate.form.buttons.save'
                )
              : i18n.t(
                  'drawers.userDetailDrawer.aliasesCard.dialogs.aliasCreate.form.buttons.showChanges'
                )
          }}
        </SmButton>
      </div>
    </template>
  </SmDialog>
</template>

<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'

  import { Account, UserAliasesService } from '@/client'
  import { editAlias } from '@/common/user'
  import validate from '@/middlewares/validate'
  import { useGlobalStore } from '@/stores/globalStore'
  import { AliasType } from '@/types/user'

  const emit = defineEmits(['update:visibility', 'refetchUser'])
  const props = defineProps({
    visibility: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object as PropType<Account>,
      required: true,
    },
    alias: {
      type: Object as PropType<AliasType>,
      default: null,
    },
  })

  const store = useGlobalStore()
  const i18n = useI18n()

  const visibility = computed({
    get: () => props.visibility,
    set: (value) => emit('update:visibility', value),
  })

  const user = computed(() => props.user)
  const alias = computed(() => props.alias)

  const formRef = ref()
  const formData = ref({
    name: alias.value?.name || '',
    description: alias.value?.description || '',
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRules: any = {
    name: [
      {
        required: true,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        validator: (rule: any, value: string, callback: any) => {
          if (value == '' || value == null) {
            return callback('Bitte geben Sie einen Namen ein')
          }

          if ((user.value.email || '').toLowerCase() === value.toLowerCase()) {
            callback(
              new Error('Der Alias darf nicht gleich der E-Mail Adresse sein')
            )
          } else {
            callback()
          }
        },
        trigger: 'change',
      },
    ],
  }

  const createAliasResponse = ref()

  const wasPreviewed = ref<boolean>(alias.value != null)
  const saveAlias = async () => {
    if (alias.value == null) {
      // create alias
      if (!(await validate(formRef.value))) return

      UserAliasesService.addAliasApiV1ManagementUsersEmailOrIdAliasPost({
        emailOrId: user.value.email || '',
        requestBody: {
          user_name: formData.value.name,
          description: formData.value.description,
        },
        mergeUsers: true,
        force: true,
        preview: !wasPreviewed.value,
        removeFromOtherUsers: true,
      }).then((response) => {
        if (wasPreviewed.value === true) {
          visibility.value = false
          emit('refetchUser')
        } else {
          createAliasResponse.value = response
          wasPreviewed.value = true
        }
      })
    } else {
      // update alias
      editAlias(store.getIp, store.getJWT(), user.value.email || '', {
        name: formData.value.name,
        description: formData.value.description,
      }).then(() => {
        visibility.value = false
        emit('refetchUser')
      })
    }
  }
</script>
