<template>
  <div class="bg-background">
    <div class="sidebar-wrapper no-print">
      <el-menu
        ref="sidebarMenu"
        :default-active="routeName"
        :collapse="isCollapse"
        active-text-color="var(--el-color-primary)"
        text-color="#fff">
        <div class="menu-wrapper">
          <div class="logo-wrapper" @click="isCollapse = !isCollapse">
            <LogoIcon
              v-show="isCollapse"
              class="logo"
              :is-dark="true"
              style="margin-top: 40px; width: 40px" />
            <LogoText
              v-show="!isCollapse"
              class="logo"
              :is-dark="true"
              style="width: 90%" />
          </div>
          <component
            :is="item.children !== undefined ? 'el-sub-menu' : 'el-menu-item'"
            v-for="item in menu"
            :key="item.name"
            :index="
              item.route.includes('/applications')
                ? '/applications'
                : item.route
            "
            :disabled="item.disabled"
            class="settings-sidebar-item"
            :data-test-id="'sidebar-' + item.name"
            @click="() => (!item.children ? router.push(item.route) : {})">
            <v-icon :name="item.icon" scale="1.15" fill="inherit" />
            <template #title>
              {{ item.displayName }}
            </template>
            <el-menu-item
              v-for="child in item.children"
              :key="child.name"
              :index="child.route"
              :disabled="child.disabled"
              class="settings-sidebar-item child-menu-item"
              :data-test-id="'sidebar-' + child.name"
              @click="router.push(child.route)">
              <template #title>
                <img
                  v-if="!child.icon.startsWith('io-')"
                  :src="`https://logos.saasmetrix.io/${child.icon}.png`"
                  class="integration_icon" />
                <v-icon :name="item.icon" size="24px" :fill="null" />
                {{ child.displayName }}
              </template>
            </el-menu-item>
          </component>
        </div>
      </el-menu>
      <!-- This v-if checks if the the sidebar is not collapsed and -->
      <!-- If the user not exists or the trail_expire_date not exists it will return false -->
      <!-- If both exists, the trail_expire_date is not after the current date and the user -->
      <!-- is in trail mode than the content will be shown -->
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { useWindowSize } from '@vueuse/core'

  import { useI18n } from 'vue-i18n'
  import { useRoute, useRouter } from 'vue-router'

  import LogoIcon from '@/assets/img/LogoIcon.vue'
  import LogoText from '@/assets/img/LogoText.vue'
  import { Account, CompanyData, CompanyManagementService } from '@/client'
  import { addPreference, updatePreferences } from '@/common/preferences'
  import { useGlobalStore } from '@/stores/globalStore'

  const i18n = useI18n()
  const router = useRouter()
  const route = useRoute()
  const store = useGlobalStore()

  type MenuItem = {
    displayName: string
    name: string
    route: string
    icon: string
    disabled: boolean
    children?: MenuItem[]
  }

  const menu = computed((): MenuItem[] => [
    {
      displayName: i18n.t('sidebar.dashboard'),
      name: 'Dashboard',
      route: '/dashboard',
      icon: 'md-home-round',
      disabled: false,
    },
    {
      displayName: i18n.t('sidebar.applications'),
      name: 'Applications',
      route: '/applications',
      icon: 'md-gridview-round',
      disabled: false,
    },
    {
      displayName: i18n.t('sidebar.licences'),
      name: 'Licences',
      route: '/licences',
      icon: 'md-description-round',
      disabled: false,
    },
    {
      displayName: i18n.t('sidebar.external-data-sources'),
      name: 'External-Data-Sources',
      route: '/data-sources',
      icon: 'md-dns-round',
      disabled: false,
    },

    {
      displayName: i18n.t('sidebar.users'),
      name: 'Users',
      route: '/users',
      icon: 'md-person-round',
      disabled: false,
    },
    {
      displayName: i18n.t('groups'),
      name: 'Groups',
      route: '/groups',
      icon: 'md-groups-round',
      disabled: false,
    },
    {
      displayName: i18n.t('sidebar.documents'),
      name: 'Documents',
      route: '/documents',
      icon: 'md-foldercopy-round',
      disabled: false,
    },
  ])

  // Get the current most parent route name
  // e.g. /applications/1/settings/general -> applications
  const regexPath = RegExp('(/.*?)(?:/|$).*')
  const routeName = computed(() => {
    const name = route.path as string
    const result = name.match(regexPath)?.[1] ?? name
    return result
  })

  const { width } = useWindowSize()
  const isCollapse = ref<boolean>(
    sessionStorage.getItem('sidebar-collapse') !== null
      ? sessionStorage.getItem('sidebar-collapse') === 'true'
      : false
  )
  const collapsedByWatch = ref<boolean>(false)

  watch(
    () => width.value,
    () => {
      if (width.value <= 1400) {
        isCollapse.value = true
      } else isCollapse.value = false

      collapsedByWatch.value = true
    }
  )

  watch(
    () => isCollapse.value,
    () => {
      if (store.getJWT() && user.value && !collapsedByWatch.value) {
        updatePreferences(store.getIp, store.getJWT(), user.value?.email, {
          'sidebar-collapse': isCollapse.value,
        })
          .catch(() => {
            addPreference(
              store.getIp,
              store.getJWT(),
              user.value?.email || '',
              {
                'sidebar-collapse': isCollapse.value,
              }
            )
          })
          .finally(() => {
            sessionStorage.setItem(
              'sidebar-collapse',
              isCollapse.value.toString()
            )

            // This event needs to be triggered to listen to this event at every
            // point of the app. For example, the dashboard charts need to be resized if the
            // sidebar state changes.
            document.dispatchEvent(
              new CustomEvent('sidebar-collapse-state-changed', {
                detail: {
                  state: isCollapse.value.toString(),
                },
              })
            )
          })
      }

      collapsedByWatch.value = false
    }
  )

  const user = ref<Account | null>(null)
  const companyData = ref<CompanyData | null>(null)

  const logoWidth = computed(() => (!isCollapse.value ? '180px' : '40px'))
  const sidebarPadding = computed(() =>
    !isCollapse.value ? '40px 30px' : '10px'
  )
  const adminSidebarPadding = computed(() =>
    !isCollapse.value ? '0px' : '10px'
  )
  const sidebarIconColor = computed(() =>
    !isCollapse.value ? 'white' : 'black'
  )

  onMounted(() => {
    CompanyManagementService.getCompanyInfoApiV1CompanyInfoGet({}).then(
      (res) => {
        companyData.value = res
      }
    )
  })
</script>

<style lang="scss" scoped>
  .logo-wrapper {
    align-self: center;
    margin-bottom: 40px;
    width: v-bind(logoWidth);
  }
</style>
<style lang="scss">
  .sidebar-wrapper {
    position: sticky !important;
    margin: 20px 0 20px 20px;
    background-color: var(--sm-sidebar);
    padding: v-bind(sidebarPadding);
    border-radius: 25px;
    box-shadow: var(--shadow);
    height: calc(100% - 40px);
    // height: v-bind(sidebarHeight);

    .trial-information-wrapper {
      position: absolute;
      width: v-bind(logoWidth);
      align-self: center;
      bottom: 2rem;
      align-self: center;

      .text-muted {
        font-size: 1rem;
        color: var(--el-text-color-secondary);
      }

      .trail-info-icon {
        color: #fff;
        margin-left: 20px;
        cursor: pointer;
      }
    }

    .menu-wrapper {
      display: flex;
      flex-direction: column;
      margin: 0px 10px 0px 10px;
    }

    .el-menu-vertical-demo:not(.el-menu--collapse) {
      width: 200px;
      background-color: var(--sm-sidebar);

      .el-sub-menu__title {
        padding-left: v-bind(adminSidebarPadding);
      }
    }

    .el-menu-vertical-demo:is(.el-menu--collapse) {
      background-color: var(--sm-sidebar);

      .el-sub-menu__title {
        padding-left: v-bind(adminSidebarPadding);
      }
    }

    .el-menu {
      border: none !important;
      background-color: var(--sm-sidebar);

      span {
        color: #fff;

        &:hover {
          color: var(--el-color-primary) !important;
        }
      }

      .el-menu-item {
        display: flex;
        flex-direction: row;
        gap: 10px;
        padding: 0px !important;
        color: #fff;
        fill: #fff;

        &:hover {
          color: var(--el-color-primary);
          fill: var(--el-color-primary);
          background-color: var(--sm-sidebar);
        }
      }

      .el-menu-item.is-active {
        color: var(--el-color-primary);
        fill: var(--el-color-primary);
      }

      .el-menu-item .el-menu-tooltip__trigger {
        padding: 0px 10px;
      }
    }

    .el-sub-menu {
      border: none !important;
      background-color: rgba(24, 35, 46, 255);

      span {
        color: #fff;

        &:hover {
          color: var(--el-color-primary) !important;
        }
      }

      .el-menu-item.is-active {
        color: var(--el-color-primary);
        fill: var(--el-color-primary);
      }

      .el-sub-menu__title {
        display: flex;
        flex-direction: row;
        gap: 10px;
        padding-left: v-bind(adminSidebarPadding);
        color: #fff;
        fill: #fff;

        .is-active {
          color: var(--el-color-primary) !important;
        }

        &:hover {
          color: var(--el-color-primary) !important;
          background-color: var(--sm-sidebar);
        }
      }

      .admin-menu-item {
        color: v-bind(sidebarIconColor);

        &:hover {
          color: var(--el-color-primary);
        }
      }

      .el-sub-menu-item {
        display: flex;
        flex-direction: row;
        gap: 10px;
        padding: 0px !important;
        margin-left: 20px;
        color: #fff;
        fill: #fff;

        &:hover {
          color: var(--el-color-primary);
          background-color: var(--sm-sidebar);
        }
      }

      .el-sub-menu-item.is-active {
        color: var(--el-color-primary);
        fill: var(--el-color-primary);
      }

      .el-sub-menu__title .el-tooltip__trigger {
        padding-left: 10px !important;
        color: v-bind(sidebarIconColor);
      }

      .el-sub-menu__title {
        padding-left: v-bind(adminSidebarPadding) !important;
      }
    }
  }
</style>
