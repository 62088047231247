<template>
  <div>
    <TextMultiline>
      {{ i18n.t('employeeOptimizationText', { name: userDisplayName }) }}
    </TextMultiline>
  </div>
</template>

<script lang="ts" setup>
  import { DataSource, Employee, Optimization } from '@/client'
  import { useI18n } from 'vue-i18n'

  const i18n = useI18n()

  interface Props {
    optimization: Optimization
    datasource: DataSource
    employee: Employee
  }

  const userDisplayName = computed(() => {
    if (props.employee.first_name && props.employee.last_name)
      return `${props.employee.first_name} ${props.employee.last_name}`
    return props.employee.email
  })

  const props = defineProps<Props>()
</script>

<style></style>
