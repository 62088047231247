<template>
  <div>
    <AliasDialog
      v-model:visibility="aliasDialogVisibility"
      :alias="aliasDialogAlias"
      :user="user"
      @refetch-user="refetchUser()" />

    <AliasDeleteDialog
      v-if="aliasDeleteDialogVisibility && aliasDeleteDialogAlias"
      v-model:visibility="aliasDeleteDialogVisibility"
      :alias="aliasDeleteDialogAlias"
      :response="aliasDeleteDialogResponse"
      @abort="() => deleteDialogAbort()"
      @confirm="() => handleAliaseDelete(aliasDeleteDialogAlias)" />

    <div class="el-descriptions el-descriptions__title header-wrapper">
      <span>{{ i18n.t('drawers.userDetailDrawer.aliasesCard.title') }}</span>
      <div>
        <SmButton
          v-require-permission="CompanyAccessRole.ADMIN"
          outline
          size="small"
          style="margin-top: 2px"
          @click="
            () => (
              (aliasDialogAlias = undefined), (aliasDialogVisibility = true)
            )
          ">
          <v-icon name="md-add-round" class="mr-2 fill-contrast" />
          {{ i18n.t('drawers.userDetailDrawer.aliasesCard.createAlias') }}
        </SmButton>
      </div>
    </div>
    <el-table :data="aliasesData" width="100%">
      <el-table-column type="expand">
        <template #default="{ row }">
          <div style="padding: 0px 20px 20px 20px">
            <el-table :data="aliasesLicences[row.name]">
              <el-table-column
                :label="
                  i18n.t('drawers.userDetailDrawer.licencesCard.table.name')
                "
                prop="name">
                <template #default="scope">
                  <div class="table_row_cell_content">
                    <el-image
                      class="company-mini-img"
                      :src="`https://logos.saasmetrix.io/${scope.row.software_name}.png`"
                      fit="contain">
                      <template #error>
                        <v-icon name="md-hideimage-round" scale="1.4" />
                      </template>
                    </el-image>
                    <span style="margin-left: 10px">{{
                      scope.row.display_name
                        ? scope.row.display_name
                        : scope.row.software_name
                    }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Sub-Account" prop="sub_account_id">
                <template #default="scope">
                  <TextMultiline
                    v-if="
                      getSubAccountNameById(
                        scope.row.software_name,
                        scope.row.sub_account_id
                      ) != null
                    "
                    :text="
                      getSubAccountNameById(
                        scope.row.software_name,
                        scope.row.sub_account_id
                      ) as string
                    "
                    :show-tool-tip="true"
                    :lines-to-show="1"
                    tooltip-max-width="100px" />
                  <span v-else class="text-muted">{{ i18n.t('noData') }}</span>
                </template>
              </el-table-column>
              <el-table-column
                :label="
                  i18n.t(
                    'drawers.userDetailDrawer.licencesCard.table.licenceModel'
                  )
                ">
                <template #default="scope">
                  <span
                    v-if="
                      scope.row.software_licenses &&
                      scope.row.software_licenses !== 'noLicenceModel'
                    "
                    >{{ scope.row.software_licenses }}</span
                  >
                  <span v-else class="text-muted">{{
                    i18n.t('general.noModel')
                  }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name" :label="i18n.t('general.name')" />
      <el-table-column
        prop="description"
        :label="i18n.t('general.description')">
        <template #default="{ row }">
          <TextMultiline
            :text="row.description"
            :show-tool-tip="true"
            :lines-to-show="1"
            tooltip-max-width="200px" />
        </template>
      </el-table-column>
      <el-table-column
        prop="actions"
        :label="
          i18n.t('drawers.userDetailDrawer.aliasesCard.table.actions.label')
        "
        align="right">
        <template #default="{ row }">
          <div style="display: flex; justify-content: end; gap: 10px">
            <v-icon
              name="md-modeedit-round"
              style="cursor: pointer"
              @click="
                () => ((aliasDialogAlias = row), (aliasDialogVisibility = true))
              " />

            <v-icon
              name="md-delete-round"
              class="delete-button"
              scale="1"
              @click="handleAliaseDelete(row)" />
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'

  import {
    Account,
    CompanyAccessRole,
    LicenceOut,
    SoftwareOut,
    UserLicencesService,
    UsersService,
  } from '@/client'
  import { deleteAlias } from '@/common/user'
  import { sendToast } from '@/components/sm/SmNotification'
  import { useGlobalStore } from '@/stores/globalStore'
  import { AliasType } from '@/types/user'

  const emit = defineEmits(['update:user'])
  const props = defineProps({
    user: {
      type: Object as PropType<Account>,
      required: true,
    },
    softwares: {
      type: Array as PropType<SoftwareOut[]>,
      required: true,
    },
  })

  const user = computed({
    get: () => props.user,
    set: (value) => emit('update:user', value),
  })

  const softwares = computed(() => props.softwares)

  const store = useGlobalStore()
  const i18n = useI18n()

  const aliasDialogVisibility = ref<boolean>(false)
  const aliasDialogAlias = ref<AliasType>()
  const aliasesData = computed(() => {
    if (user.value && user.value.aliases) {
      return Object.entries(user.value.aliases).map(([key, value]) => ({
        name: key,
        ...value,
      }))
    }
    return []
  })

  const aliasesLicences = ref<Record<string, LicenceOut[]>>({})

  const refetchUser = () => {
    UsersService.getUserApiV1ManagementUsersEmailOrIdGet({
      emailOrId: user.value.email,
    }).then((fetchedUser) => {
      user.value = fetchedUser
    })
  }

  const aliasDeleteDialogVisibility = ref<boolean>(false)
  const aliasDeleteDialogAlias = ref<AliasType>()
  const aliasDeleteDialogResponse = ref()
  const handleAliaseDelete = (row?: AliasType) => {
    if (!row) {
      return sendToast(
        i18n.t('error.request.unknown.title'),
        i18n.t('error.request.unknown.message'),
        'error'
      )
    }

    deleteAlias(
      store.getIp,
      store.getJWT(),
      user.value.email,
      row,
      aliasDeleteDialogAlias.value == null,
      true
    ).then((response) => {
      if (aliasDeleteDialogAlias.value == null) {
        aliasDeleteDialogResponse.value = response
        aliasDeleteDialogAlias.value = row
        aliasDeleteDialogVisibility.value = true
      } else {
        refetchUser()
        aliasDeleteDialogVisibility.value = false
      }
    })
  }

  const deleteDialogAbort = () => {
    aliasDeleteDialogResponse.value = undefined
    aliasDeleteDialogAlias.value = undefined
    aliasDeleteDialogVisibility.value = false
  }

  const getSubAccountNameById = (
    softwareName: string,
    subAccountId: string
  ): string | null => {
    const software = softwares.value.find(
      (software) => software.software_name === softwareName
    )

    if (software == null) return null
    if (software.sub_accounts == null) return null
    if (software.sub_accounts[subAccountId] == null) return null

    return software.sub_accounts[subAccountId].name
  }

  onMounted(() => {
    if (user.value && user.value.aliases) {
      Object.keys(user.value.aliases || {}).forEach((alias) => {
        UserLicencesService.getLicencesApiV1SoftwareLicencesGet({
          email: alias,
        }).then((licences) => {
          // Check if the alias is still in the list
          aliasesLicences.value[alias] = licences
        })
      })
    }
  })
</script>

<style scoped>
  .company-mini-img {
    width: 20px;
    height: 20px;
  }
  .table_row_cell_content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .text-muted {
    color: var(--el-text-color-secondary);
    font-style: italic;
    gap: 0.5rem;
    display: flex;
    align-items: center;
  }
  .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  .delete-button:hover {
    color: var(--el-color-danger);
  }
</style>
