import messages from '@intlify/unplugin-vue-i18n/messages'
import { createI18n } from 'vue-i18n'

import { useGlobalStore } from '@/stores/globalStore'

let store = null

try {
  store = useGlobalStore()
} catch (error) {
  store = null
}

const locale =
  store !== null && store.state.user.language !== undefined
    ? store.state.user.language
    : navigator.language

const i18n = createI18n({
  locale: locale,
  fallbackLocale: 'en',
  legacy: false,
  globalInjection: true,
  messages,
})

export default i18n
