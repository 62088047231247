import { addRxPlugin, createRxDatabase, RxCollection, RxDatabase } from 'rxdb'
import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode'
import { getRxStorageMemory } from 'rxdb/plugins/storage-memory'

import { Licence_Output } from '@/client'

import { computedAsync } from '@vueuse/core'
import { initLicenseCollection } from './licenseV2.store'

if (process.env.NODE_ENV === 'development') {
  // in dev-mode we add the dev-mode plugin
  // which does many checks and adds full error messages
  addRxPlugin(RxDBDevModePlugin)
}

// Typing
// and then merge all our types
export type LicenceCollection = RxCollection<Licence_Output>

export type DatabaseCollections = {
  licenses: LicenceCollection
}

export type Database = RxDatabase<DatabaseCollections>

let _db: Database

export const db = computedAsync<Database>(async () => {
  if (!_db) {
    await createDatabase()
  }
  return _db
})

export async function createDatabase() {
  _db = await createRxDatabase({
    name: 'saasmetrix',
    storage: getRxStorageMemory(),
    allowSlowCount: true,
  })

  // Create collections
  initLicenseCollection()
}
