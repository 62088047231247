import { LicenceStatus } from '@/client'

export interface LicenceFilter {
  getFilter(): Record<string, unknown>
  name: string
}

export class AccountFilter implements LicenceFilter {
  accountID: string
  name = 'AccountFilter'

  constructor(accountID: string) {
    this.accountID = accountID
  }

  getFilter() {
    return {
      account_id: {
        $eq: this.accountID,
      },
    }
  }
}

export class SoftwaresFilter implements LicenceFilter {
  name = 'SoftwaresFilter'
  softwareIDs: string[]
  operator: 'and' | 'or' = 'and'

  constructor(softwareIDs: string[], operator: 'and' | 'or' = 'and') {
    this.softwareIDs = softwareIDs
    this.operator = operator
  }

  getFilter() {
    if (this.softwareIDs.length === 0) {
      return {}
    }

    return {
      [`$${this.operator}`]: this.softwareIDs.map((id) => ({
        software_id: { $eq: id },
      })),
    }
  }
}

export class EmailFilter implements LicenceFilter {
  email: string
  name = 'EmailFilter'
  operator: 'and' | 'or' = 'and'

  constructor(email: string, operator?: 'and' | 'or') {
    this.email = email

    if (operator) {
      this.operator = operator
    }
  }

  getFilter() {
    return {
      [`$${this.operator}`]: [
        {
          email: {
            $regex: this.email,
            $options: 'i',
          },
        },
      ],
    }
  }
}

export class NameFilter implements LicenceFilter {
  username: string
  name = 'NameFilter'
  operator: 'and' | 'or' = 'and'

  constructor(username: string, operator: 'and' | 'or') {
    this.username = username
    this.operator = operator
  }

  getFilter() {
    return {
      [`$${this.operator}`]: [
        {
          name: {
            $regex: this.username,
            $options: 'i',
          },
        },
      ],
    }
  }
}

export type GuestFilterMode = 'guest' | 'non-guest' | 'all'
export class GuestFilter implements LicenceFilter {
  name = 'GuestFilter'
  mode: GuestFilterMode

  constructor(mode: GuestFilterMode) {
    this.mode = mode
  }

  query = () => {
    switch (this.mode) {
      case 'guest':
        return { is_guest: { $eq: true } }
      case 'non-guest':
        return { is_guest: { $eq: false } }
      case 'all':
        return {}
    }
  }

  getFilter() {
    return {
      ...this.query(),
    }
  }
}

export class StatusFilter implements LicenceFilter {
  name = 'StatusFilter'
  status: LicenceStatus[] | null // Null means all status

  constructor(status: LicenceStatus[] | null) {
    this.status = status
  }

  getFilter() {
    if (this.status === null) {
      return {}
    }

    if (this.status.length === 0) {
      return {}
    }

    return {
      status: {
        $in: this.status,
      },
    }
  }
}

export class SoftwareLicensesFilter implements LicenceFilter {
  name = 'SoftwareLicensesFilter'
  licenses: string[] | null // Null means all models
  operator: 'and' | 'or' = 'and'

  constructor(licenses: string[] | null, operator: 'and' | 'or' = 'and') {
    this.licenses = licenses
    this.operator = operator
  }

  _generateFilter = (license: string) => {
    return { [`software_licenses.${license}`]: { $exists: true } }
  }

  getFilter() {
    if (this.licenses === null) {
      return {}
    }

    if (this.licenses.length === 0) {
      return {}
    }

    return {
      [`$${this.operator}`]: this.licenses.map(this._generateFilter),
    }
  }
}

export class SubAccountFilter implements LicenceFilter {
  name = 'LicenceSubAccountFilter'
  subaccounts: string[]

  constructor(subaccounts: string[]) {
    this.subaccounts = subaccounts
  }

  getFilter() {
    if (this.subaccounts.length === 0) {
      return {}
    }

    return {
      sub_account_id: {
        $in: this.subaccounts,
      },
    }
  }
}

export const filters = {
  AccountFilter,
  SoftwaresFilter,
  EmailFilter,
  NameFilter,
  SoftwareLicensesFilter,
  GuestFilter,
  StatusFilter,
  SubAccountFilter,
}
