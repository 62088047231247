<template>
  <SmDialog
    v-model:visibility="visible"
    :title="t('activity.activityInformation')">
    <el-descriptions border :column="1">
      <el-descriptions-item width="5rem" label-class-name="description-label">
        <template #label>
          <span class="cell-item">{{ t('general.action') }} </span>
        </template>
        {{
          t(
            `activity.${activity.action}`,
            getI18nArgs(
              activity,
              allUser,
              allSoftware,
              allSoftwareLicenses,
              allPriceInformations,
              userProfiles[activity.identity_id]
            )
          )
        }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="description-label">
        <template #label>
          <span class="cell-item"> {{ t('general.timestamp') }} </span>
        </template>
        {{ timestamp }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="description-label">
        <template #label>
          <span class="cell-item"> {{ t('general.user') }} </span>
        </template>
        {{ accEmail }}
      </el-descriptions-item>
    </el-descriptions>
  </SmDialog>
</template>

<script setup lang="ts">
  import dayjs from 'dayjs'

  import { useI18n } from 'vue-i18n'

  import {
    Account,
    ActivityBaseModel,
    PriceInformation,
    SoftwareLicense,
    SoftwareOut,
    UserProfilePublic,
  } from '@/client'
  import { getI18nArgs } from '@/common/util/activityUtil'

  const { t } = useI18n()
  const props = defineProps<{
    activity: ActivityBaseModel
    userProfiles: Record<string, UserProfilePublic>
    visible: boolean
    allUser: Account[]
    allSoftware: SoftwareOut[]
    allSoftwareLicenses: SoftwareLicense[]
    allPriceInformations: PriceInformation[]
  }>()
  const emit = defineEmits(['update:visible'])

  const visible = computed({
    get: () => props.visible,
    set: (v) => {
      emit('update:visible', v)
    },
  })

  const timestamp = computed(() =>
    dayjs(props.activity.created).format('DD.MM.YYYY HH:MM')
  )

  const accEmail = computed(() => {
    return props.userProfiles[props.activity.identity_id]?.email
  })
</script>
