<template>
  <div class="rounded-sm bg-gray-100 p-1 px-2 dark:bg-gray-700">
    <SmTooltip :active="!hideTooltip">
      <template #content>
        <slot name="tooltip">
          <span v-if="props.tooltip">{{ props.tooltip }}</span>
          <span v-else-if="props.label">{{ props.label }}</span>
        </slot>
      </template>

      <slot name="label">
        <div class="flex items-center gap-2">
          <v-icon v-if="props.icon" :name="props.icon" scale=".9" />
          <TextMultiline
            :text="props.label || i18n.t('unknown')"
            :lines-to-show="1"
            :show-tool-tip="true" />
          <slot name="action"></slot>
        </div>
      </slot>
    </SmTooltip>
  </div>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'
  const i18n = useI18n()

  const props = defineProps({
    label: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    hideTooltip: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
  })
</script>
