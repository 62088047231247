<template>
  {{ props.selection }}
  <div>Hallo</div>
</template>

<script setup lang="ts">
  import { AccountGroup, AccountGroupIn, AccountGroupUpdateIn } from '@/client'
  import { SelectionState } from './GroupAddSoftware.vue'

  interface Props {
    accountGroup: AccountGroupIn | AccountGroup | AccountGroupUpdateIn
    selection: SelectionState
  }

  const props = defineProps<Props>()
</script>
