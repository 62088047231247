<template>
  <SmDialog v-model:visibility="localVisibility" size="small">
    <div style="width: 100%">
      <div style="display: table; margin: 0 auto">
        <v-icon name="io-warning" scale="7" fill="#000"></v-icon>
      </div>
      <div style="display: table; margin: 20px auto 0 auto">
        {{ i18n.t('dialogs.cancelSubscriptionDialog.text') }}
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="localVisibility = false">{{
          i18n.t('general.close')
        }}</el-button>
        <el-button
          type="danger"
          @click="
            () => {
              handleClick()
            }
          "
          >{{
            i18n.t('dialogs.cancelSubscriptionDialog.confirmText')
          }}</el-button
        >
      </span>
    </template>
  </SmDialog>
</template>

<script lang="ts" setup>
  import { useI18n } from 'vue-i18n'
  import { useRouter } from 'vue-router'

  import { BillingService } from '@/client'
  import { sendToast } from '@/components/sm/SmNotification'
  import { useGlobalStore } from '@/stores/globalStore'

  const props = defineProps({
    visibility: {
      type: Boolean,
      default: true,
    },
  })
  const emit = defineEmits(['update:visibility'])

  const store = useGlobalStore()
  const router = useRouter()
  const i18n = useI18n()

  const localVisibility = computed({
    get: () => props.visibility,
    set: (value) => emit('update:visibility', value),
  })

  const handleClick = async () => {
    await BillingService.cancelSubscriptionApiV1BillingSubscriptionCancelPost({
      requestBody: {},
    })
      .then(() => {
        sendToast(
          i18n.t(
            'notifications.subscriptionCancelDialog.cancelSubscriptionSuccess.title'
          ),
          i18n.t(
            'notifications.subscriptionCancelDialog.cancelSubscriptionSuccess.message'
          ),
          'success'
        )
      })
      .catch(() => {
        sendToast(
          i18n.t(
            'notifications.subscriptionCancelDialog.cancelSubscriptionFailed.title'
          ),
          i18n.t(
            'notifications.subscriptionCancelDialog.cancelSubscriptionFailed.message',
            {
              supportEmail: 'support@saasmetrix.io',
            }
          ),
          'error'
        )
      })

    localVisibility.value = false
    router.push('/logout')
  }
</script>
