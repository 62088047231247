<template>
  <ExportDialog
    v-model:visibility="visibilityExportDialog"
    :software-id="softwareId" />

  <SmDialog
    v-model:visibility="showLicenseImportDialog"
    :title="i18n.t('licenseCsvImport.csvImportHeading')">
    <LicenseCsvImportContent
      v-if="software"
      :software-id="software._id"
      @successful-import="handleSuccessfulImport" />
  </SmDialog>

  <LicenseAddDialog
    v-if="software"
    v-model:visibility="visibilityLicenseAddDialog"
    :licenses="licenses"
    :software-licenses="softwareLicensesWithSelectedSubAccount"
    :software="software"
    :sub-account="currentSubAccount"
    @user-added="handleRefetchLicences()" />

  <ApplicationDetailDrawer
    v-if="software"
    v-model:showDrawer="showApplicationEditDialog"
    v-model:tab="licenceViewMode"
    v-model:software="software"
    :current-licences="licenses"
    :selected-sub-account-id="
      applicationDetailsStore.$state.selectedSubAccount?.id || null
    "
    @software-changed="handleRefetchSoftware()"
    @user-saved="handleRefetchLicences()" />

  <div class="home">
    <ViewHeader
      back-arrow-route="/applications"
      title-i18n-code="views.applicationDetails.title" />
    <div class="application-details-grid">
      <ApplicationDetailsHeader
        ref="statsCard"
        data-tutorial-id="application-details-stats-card"
        class="header-card"
        :software="software"
        @open-application-details-drawer="
          (tabName: string) => (
            (showApplicationEditDialog = true), (licenceViewMode = tabName)
          )
        " />
      <ApplicationDetailsLicencesTable
        v-if="software && currentSubAccount"
        ref="licenseTable"
        v-model:sub-account="currentSubAccount"
        data-tutorial-id="application-details-licences-card"
        class="licenses-card"
        :software="software"
        :software-licenses="allSoftwareLicenses"
        @export-licenses="visibilityExportDialog = true"
        @licenses-changed="handleLicensesChanged"
        @open-add-user="visibilityLicenseAddDialog = true"
        @import-licenses="showLicenseImportDialog = true"
        @open-application-details-drawer="
          (tabName: string) => (
            (showApplicationEditDialog = true), (licenceViewMode = tabName)
          )
        " />

      <SavingsCard
        v-if="software"
        data-tutorial-id="application-details-chart-card"
        :software-id="software._id"
        class="savings-card"
        @empty-state-button-clicked="
          () => (
            (showApplicationEditDialog = true), (licenceViewMode = 'Select')
          )
        " />

      <!-- Combo Card -->
      <el-card
        class="combo-card"
        :body-style="{
          height: '100%',
          'padding-top': '10px',
        }">
        <el-tabs
          v-model="comboCardActiveName"
          class="demo-tabs"
          style="height: 100%">
          <!-- Optimizations -->
          <el-tab-pane
            :label="i18n.t('optimizations')"
            name="first"
            lazy
            :style="tabPaneStyle">
            <OptimizationsList
              :all-software-licenses="allSoftwareLicenses"
              :loading="loadingOptimizations"
              :optimizations="optimizations || []"
              empty-state />
          </el-tab-pane>

          <!-- License Model Edit -->
          <el-tab-pane
            :label="i18n.t('licenses')"
            name="second"
            lazy
            :style="tabPaneStyle">
            <LicenseModelEditTable
              v-if="software"
              :software-licenses="allSoftwareLicenses"
              hide-header
              :software="software"
              :selectable="false"
              licenses-editable
              show-add-row
              :loading="loadingLicences"
              :sub-account-id="currentSubAccount?.id"
              @software-changed="handleRefetchSoftware()"
              @licenses-changed="fetchData()" />
          </el-tab-pane>
          <el-tab-pane
            :label="i18n.t('documents')"
            name="third"
            lazy
            :style="tabPaneStyle">
            <DocumentCardDocumentsList
              v-if="software"
              show-add-row
              :software-id="software._id" />
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </div>
  </div>
</template>

<!-- eslint-disable @typescript-eslint/no-non-null-assertion -->
<script lang="ts" setup>
  import { getSoftwareLicenses } from '@/common/license'
  import { ComponentExposed } from 'vue-component-type-helpers'
  import { useI18n } from 'vue-i18n'
  import { useRoute } from 'vue-router'

  import {
    Optimization,
    OptimizationStatus,
    OptimizationsService,
    SoftwareLicense,
    SoftwareOut,
    SubAccount_Output,
  } from '@/client'
  import LicenseAddDialog from '@/components/LicenseAddDialog.vue'
  import OptimizationsList from '@/components/OptimizationsList.vue'
  import ViewHeader from '@/components/ViewHeader.vue'
  import { useSoftwareStore } from '@/stores/softwareStore'

  import LicenseModelEditTable from '../../components/LicenseModelEditTable.vue'
  import { useApplicationDetailsStore } from './ApplicationDetailsView.store'
  import ApplicationDetailDrawer from './components/ApplicationDetailDrawer/ApplicationDetailDrawer.vue'
  import DocumentCardDocumentsList from './components/ApplicationDetailsDocumentCard/components/DocumentCardDocumentsList.vue'
  import ApplicationDetailsHeader from './components/ApplicationDetailsHeader.vue'
  import ApplicationDetailsLicencesTable from './components/ApplicationDetailsLicencesTable/ApplicationDetailsLicencesTable.vue'
  import SavingsCard from './components/ApplicationDetailsSavingsCard/ApplicationDetailsSavingsCard.vue'

  const i18n = useI18n()
  const isSSO = computed(() => route.query.sso === 'true')

  const softwareStore = useSoftwareStore()
  const applicationDetailsStore = useApplicationDetailsStore()
  const route = useRoute()
  const showLicenseImportDialog = ref(false)
  const statsCard = ref<ComponentExposed<typeof ApplicationDetailsHeader>>()

  const licenseTable =
    ref<ComponentExposed<typeof ApplicationDetailsLicencesTable>>()
  const visibilityLicenseAddDialog = ref(false)
  const currentSubAccount = ref<SubAccount_Output>()
  const visibilityExportDialog = ref<boolean>(false)

  const optimizations = ref<Optimization[] | null>(null)
  const loadingOptimizations = ref<boolean>(true)
  const loadingLicences = ref<boolean>(true)

  // Combo Card
  const comboCardActiveName = ref('second')

  const tabPaneStyle = {
    'max-height': 'calc(100% - 55px)',
    'overflow-y': 'auto',
  }

  // ******************************* SOFTWARE *******************************
  // Get the Softwarename
  const softwareId = ref(route.params.id as string)
  const software = ref<SoftwareOut>()
  const softwareLicenses = ref<
    {
      license: SoftwareLicense
    }[]
  >([])
  // Refetch the software data
  watch(
    softwareId,
    async (newId: string) => {
      if (newId) {
        software.value = await softwareStore.getSoftwareById(newId)
        if (software.value?.sub_accounts) {
          setDefaultSubAccount()
        }
      }
    },
    { immediate: true }
  )
  watch(
    () => softwareStore.software,
    async () => {
      software.value = await softwareStore.getSoftwareById(softwareId.value)
    }
  )

  const allSoftwareLicenses = computed<SoftwareLicense[]>(() => {
    return softwareLicenses.value.map((s) => s.license)
  })

  const softwareLicensesWithSelectedSubAccount = computed(() => {
    return softwareLicenses.value
      .filter(
        (s) =>
          s.license.sub_account_id === currentSubAccount.value?.id ||
          s.license.sub_account_id === null
      )
      .map((s) => s.license)
  })

  // ******************************* USER *******************************

  const showApplicationEditDialog = ref(route.query.edit === 'true' || false)
  const licenceViewMode = ref('EditSoftware')

  function handleSuccessfulImport() {
    showLicenseImportDialog.value = false
    handleRefetchLicences()
  }

  // reload softwareData to update the displayed values in the basic-information
  async function handleRefetchLicences() {
    licenseTable.value?.handleLicencesRefetch({ cached: false })
  }

  async function fetchData() {
    if (licenseTable.value) licenseTable.value.refetchSoftwareLicenses()

    software.value = await softwareStore.getSoftwareById(softwareId.value)

    // If the software is not found, reload the software cache and try again
    if (!software.value) {
      await softwareStore.getSoftware({ allowCached: false })
      software.value = await softwareStore.getSoftwareById(softwareId.value)
    }

    if (software.value) {
      applicationDetailsStore.initSoftware(software.value)
    }

    if (!isSSO.value) {
      software.value = await softwareStore.getSoftwareById(softwareId.value, {
        cached: false,
      })
    }

    if (software.value?.sub_accounts) {
      setDefaultSubAccount()
    }

    // update all software licenses with price

    softwareLicenses.value = await getSoftwareLicenses(
      softwareId.value
    ).finally(() => {
      loadingLicences.value = false
    })

    if (software.value) {
      applicationDetailsStore.initLicenseModels(softwareLicenses.value)
    }
    // ******************************* OPTIMIZATIONS *******************************
    OptimizationsService.getOptimizationsApiV1ManagementOptimizationsGet({
      softwareId: softwareId.value,
      status: OptimizationStatus.OPEN,
    }).then((response) => {
      optimizations.value = response
      loadingOptimizations.value = false
    })
  }

  // Update domain
  watch([software, currentSubAccount], async () => {
    if (software.value) {
      const query = route.query
      const queryString = Object.keys(query)
        .map((key) => `${key}=${query[key]}`)
        .join('&')
      // Replace route without reloading
      window.history.replaceState(
        undefined,
        '',
        `/applications/${software.value._id}/${
          currentSubAccount.value?.id || ''
        }?${queryString}`
      )
    }
  })

  // ******************************* Hooks *******************************
  onBeforeUnmount(() => {
    applicationDetailsStore.$reset()
  })

  onMounted(async () => {
    fetchData()
  })

  // ******************************* Functions *******************************
  async function handleRefetchSoftware() {
    softwareStore
      .getSoftwareById(softwareId.value, {
        cached: isSSO.value,
        useLocalCache: false,
      })
      .then((res) => {
        software.value = res
        setDefaultSubAccount()
      })

    softwareLicenses.value = await getSoftwareLicenses(softwareId.value)
  }

  function handleLicensesChanged() {
    // Refetch the statistics
    statsCard.value?.fetchStatistics(softwareId.value)
  }

  const licenses = computed(() => {
    return licenseTable.value?.lincenses
  })

  function setDefaultSubAccount() {
    if (!software.value?.sub_accounts) return

    // Check if subaccounts is in route (/applications/:id/:subAccountId)
    if (
      route.params.subAccountId &&
      typeof route.params.subAccountId === 'string'
    ) {
      currentSubAccount.value =
        software.value?.sub_accounts?.[route.params.subAccountId]
    } else {
      currentSubAccount.value =
        software.value?.sub_accounts![
          Object.keys(software.value?.sub_accounts)[0]
        ]
    }
  }
</script>

<style lang="scss" scoped>
  /* Header */
  .with-back-arrow {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }
  .back-arrow {
    transition: all 0.4s ease-in-out;
  }
  .with-back-arrow:hover .back-arrow {
    color: var(--el-color-primary);
    animation: back-button-hover;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
  @keyframes back-button-hover {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.2);
    }

    100% {
      transform: scale(1);
    }
  }

  .application-details-grid {
    display: grid;
    width: 100%;
    gap: 20px;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows:
      auto minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)
      minmax(0, 1fr) minmax(0, 1fr);
    grid-template-areas:
      'headerCard headerCard headerCard headerCard headerCard headerCard'
      'licensesCard licensesCard licensesCard licensesCard savingsCard savingsCard'
      'licensesCard licensesCard licensesCard licensesCard savingsCard savingsCard'
      'licensesCard licensesCard licensesCard licensesCard comboCard comboCard'
      'licensesCard licensesCard licensesCard licensesCard comboCard comboCard'
      'licensesCard licensesCard licensesCard licensesCard comboCard comboCard'
      'licensesCard licensesCard licensesCard licensesCard comboCard comboCard';

    .header-card {
      grid-area: headerCard;
    }

    .licenses-card {
      grid-area: licensesCard;
    }

    .savings-card {
      grid-area: savingsCard;
    }

    .combo-card {
      grid-area: comboCard;
    }
  }
</style>

<style lang="scss">
  .application-details-grid {
    .el-tabs__content {
      height: 100%;
    }
  }
</style>
