import {
  Account,
  ActivityBaseModel,
  SoftwareLicense,
  SoftwareOut,
  UserProfilePublic,
  PriceInformation,
} from '@/client'
import { licenseStore } from '@/stores/licenseV2.store'

export function getI18nArgs(
  activity: ActivityBaseModel,
  users: Account[],
  applications: SoftwareOut[],
  softwareLicenses: SoftwareLicense[],
  allPriceInformations: PriceInformation[],
  userProfile: UserProfilePublic
) {
  let textName: string | undefined
  let softwareName: string | undefined

  const crud_id =
    activity.data.created_id ||
    activity.data.deleted_id ||
    activity.data.updated_id

  switch (activity.action) {
    case 'licence.create':
    case 'licence.update':
    case 'licence.update_resend_invitation':
    case 'licence.update_assign_account':
    case 'licence.update_unassign_account':
    case 'licence.delete':
      if (!crud_id) break
      licenseStore.getByID(crud_id).then((license) => {
        if (!license) return
        const license_account = users.find((u) => u._id == license?.account_id)
        textName = license_account?.name || license?.email
        const app = applications.find(
          (_app) => _app._id == license?.software_id
        )
        softwareName = app?.display_name || app?.software_name
      })

      break

    case 'account.create':
    case 'account.create_from_alias':
    case 'account.update':
    case 'account.delete':
    case 'account.deactivate':
    case 'account.password.change':
    case 'account.password.reset':
    case 'account.alias.create':
    case 'account.alias.update':
    case 'account.alias.merge':
    case 'account.alias.delete':
      const user = users.find((u) => u._id == crud_id)
      textName = user?.name || user?.email
      break
    case 'login.password':
      break
    case 'software.create':
    case 'software.create_oauth':
    case 'software.re_authorize':
    case 'software.re_authorize_oauth':
    case 'software.update':
    case 'software.delete':
      softwareName = applications.find((s) => s._id == crud_id)?.software_name
      break
    case 'software.update_software_license.create':
    case 'software.update_software_license.update':
    case 'software.update_software_license.delete':
    case 'software_license.create':
    case 'software_license.update':
    case 'software_license.delete':
      const license = softwareLicenses.find((s) => s._id == crud_id)
      softwareName = applications.find(
        (s) => s._id == license?.software_id
      )?.software_name
      break
    case 'software_license_price_information.create':
    case 'software_license_price_information.update':
    case 'software_license_price_information.delete':
      const priceInfo = allPriceInformations.find((s) => s._id == crud_id)
      const softwareLicense = softwareLicenses.find(
        (s) => s._id == priceInfo?.software_license_id
      )
      softwareName = applications.find(
        (s) => s._id == softwareLicense?.software_id
      )?.software_name
      break
  }

  return {
    email: textName,
    softwareName: softwareName,
    updated_licences: '',
    performedBy: userProfile.display_name,
  }
}
