<template>
  <el-card shadow="always" class="group-card">
    <div class="group-card__header">
      <div style="display: flex">
        <TextMultiline style="padding-right: 0.5rem">
          <h3 @click="handleView">{{ props.group.name }}</h3>
        </TextMultiline>
        <SmTooltip v-if="props.group.data_source?.data_source_id">
          <el-image
            src="https://logos.saasmetrix.io/Entra.png"
            style="width: 20px; padding-left: 0.5rem" />

          <template #content>
            <span>
              {{ i18n.t('azureSyncShort') }}
            </span>
          </template>
        </SmTooltip>
      </div>
      <div class="group-card__header__actions">
        <v-icon
          v-show="!props.group.data_source?.data_source_id"
          name="md-personaddalt1-round"
          @click="emit('addUser', props.group)" />
        <v-icon name="io-create" @click="emit('edit', props.group)" />
      </div>
    </div>
    <span class="group-card__user-count" @click="handleView"
      >{{ props.group.accounts.length }} {{ i18n.t('user') }}</span
    >

    <div class="softwares">
      <div v-for="software in softwares" :key="software._id" class="software">
        <SmTooltip :content="software.display_name || software.software_name">
          <div class="flex">
            <ApplicationIcon
              size="20px"
              :software-name="software.software_name" />
          </div>
        </SmTooltip>
      </div>
    </div>
  </el-card>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { AccountGroup, SoftwareOut } from '@/client'
  import { useSoftwareStore } from '@/stores/softwareStore'
  import TextMultiline from './sm/TextMultiline.vue'

  const i18n = useI18n()
  const softwareStore = useSoftwareStore()
  const allSoftwares = ref<SoftwareOut[]>([])

  const emit = defineEmits(['edit', 'addUser', 'view'])

  const props = defineProps({
    group: {
      type: Object as PropType<AccountGroup>,
      required: true,
    },
  })

  const softwareIDs = computed(() => {
    return Object.keys(props.group.permission.software_permissions)
  })

  const softwares = computed<SoftwareOut[]>(() => {
    return allSoftwares.value.filter((s) => softwareIDs.value.includes(s._id))
  })

  function handleView() {
    emit('view', props.group)
  }

  onMounted(async () => {
    allSoftwares.value = await softwareStore.getSoftware()
  })
</script>

<style scoped lang="scss">
  .group-card {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h3 {
        margin: 0;
      }
    }

    &__header__actions {
      display: flex;
      align-items: center;
      gap: 0.6rem;

      svg {
        cursor: pointer;
        fill: var(--sm-contrast);
      }
    }

    &__user-count,
    h3 {
      cursor: pointer;
    }
  }

  //   Images

  $image-size: 32px;
  .softwares {
    margin-top: 0.6rem;
    height: $image-size;
    margin-bottom: 0.6rem;
    margin-left: 18px;

    display: flex;
  }

  .software {
    display: flex;
    align-items: center;
    justify-content: center;

    width: $image-size;
    min-width: $image-size;
    height: $image-size;
    padding: 0.5rem;

    box-shadow: var(--shadow);
    background-color: white;

    border-radius: 50%;

    position: relative;

    transition: all 0.2s ease-in-out;
  }

  .software:hover {
    box-shadow: shadow;
    z-index: 1000;
    scale: 1.05;
  }

  //   Make the images overlap
  @for $i from 1 through 10 {
    .software:nth-child(#{$i}) {
      z-index: $i;
      transform: translateX(calc(-#{$i} * $image-size / 2));
    }
  }

  //   If hover move all images to the right except the hovered one
  @for $i from 1 through 10 {
    .software:hover ~ .software:nth-child(#{$i}) {
      transform: translateX(calc(-#{$i} * $image-size / 2 + $image-size / 2));
    }
  }
</style>
